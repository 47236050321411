import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect, ConnectedProps } from 'react-redux';

import firebase from '../firebase';
import { RootState } from '../store';
import * as createAccountActions from '../store/createAccount/actions';
import * as confirmActions from '../store/confirm/actions';
import i18n from '../utils/i18n';
import {
  failToCreateAccountTitle,
  completeCreateAccount,
  doesntMatchPasswordAndPasswordConfirmation,
} from '../utils/text';

const mapStateToProps = (state: RootState) => {
  const { createAccount } = state;
  return {
    email: createAccount.email,
    password: createAccount.password,
    passwordConfirmation: createAccount.passwordConfirmation,
  };
};

const mapDispatchToProps = {
  onChangeEmail: createAccountActions.onChangeEmail,
  onChangePassword: createAccountActions.onChangePassword,
  onChangePasswordConfirmation: createAccountActions.onChangePasswordConfirmation,
  closeCreateAccountModal: createAccountActions.closeCreateAccountModal,
  openConfirm: confirmActions.openConfirm,
  closeConfirm: confirmActions.closeConfirm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const stopPropagation = (event: any) => event.stopPropagation();

const AccountForm = (props: PropsFromRedux) => {
  const {
    email,
    password,
    passwordConfirmation,
    onChangeEmail,
    onChangePassword,
    onChangePasswordConfirmation,
    closeCreateAccountModal,
    openConfirm,
    closeConfirm,
  } = props;

  const handleCreate = () => {
    if (password !== passwordConfirmation) {
      openConfirm({
        type: 'error',
        title: failToCreateAccountTitle,
        description: doesntMatchPasswordAndPasswordConfirmation,
      });
      return;
    }

    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(() => {
        const onConfirm = () => {
          closeCreateAccountModal();
          closeConfirm();
        };
        openConfirm({
          type: 'success',
          title: completeCreateAccount,
          description: '',
          confirm: onConfirm,
        });
      })
      .catch((error) => {
        const i18nMessage = i18n.firebaseErrors(error.code);
        openConfirm({
          type: 'error',
          title: failToCreateAccountTitle,
          description: i18nMessage,
        });
      });
  };

  return (
    <CSSTransition in timeout={200} classNames="fade" appear>
      <div className="overlay" onClick={closeCreateAccountModal}>
        <div className="accountForm" onClick={stopPropagation}>
          <div className="accountFormHeader">
            <div className="accountFormHeader__title">アカウント作成</div>
          </div>
          <div className="formContainer">
            <div className="formRow">
              <div className="formRow__label">メールアドレス</div>
              <input
                type="text"
                className="formRow__textInput"
                placeholder="Email"
                value={email}
                onChange={(event) => onChangeEmail(event.target.value)}
              />
            </div>
            <div className="formRow">
              <div className="formRow__label">パスワード</div>
              <input
                type="password"
                className="formRow__textInput"
                placeholder="Password"
                value={password}
                onChange={(event) => onChangePassword(event.target.value)}
              />
            </div>
            <div className="formRow">
              <div className="formRow__label">パスワード（確認用）</div>
              <input
                type="password"
                className="formRow__textInput"
                placeholder="Password confirmation"
                value={passwordConfirmation}
                onChange={(event) => onChangePasswordConfirmation(event.target.value)}
              />
            </div>
            <button type="button" className="formButton" onClick={handleCreate}>アカウント作成</button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default connector(AccountForm);
