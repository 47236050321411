import createAxiosInstance from '../../configureAxios';
import { AppDispatch } from '..';
import Utils from '../../utils/utils';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import { GET_ALL_AGENT } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getALlAgents = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/agent').catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_ALL_AGENT, payload: response.data.agents });
};
