import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, ConnectedProps } from 'react-redux';

import { INITIAL_TARGET_ID } from '../store/notice/types';
import { RootState } from '../store';
import * as noticeActions from '../store/notice/actions';
import * as confirmActions from '../store/confirm/actions';
import Loading from './Loading';
import Utils from '../utils/utils';
import {
  createNoticeText,
  confirmDeliverJustNow,
  deleteNoticeText,
  failToCreateNotice,
  failToUpdateNotice,
  withoutStartDate,
} from '../utils/text';

import 'react-datepicker/dist/react-datepicker.css';

const mapStateToPropsForNotice = (state: RootState) => {
  const { notice } = state;
  return {
    formShown: notice.formShown,
    notices: notice.notices,
    loading: notice.loading,
  };
};

const mapDispatchToPropsForNotice = {
  switchToForm: noticeActions.switchToForm,
  switchToDetail: noticeActions.switchToDetail,
  closeNoticeModal: noticeActions.closeNoticeModal,
  getAllNotice: noticeActions.getAllNotice,
};

const connector = connect(mapStateToPropsForNotice, mapDispatchToPropsForNotice);

type PropsFromRedux = ConnectedProps<typeof connector>

const stopPropagation = (event: any) => event.stopPropagation();

const Notice = (props: PropsFromRedux) => {
  const {
    formShown,
    notices,
    loading,
    switchToForm,
    switchToDetail,
    closeNoticeModal,
    getAllNotice,
  } = props;

  useEffect(() => {
    getAllNotice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickList = (event: any) => {
    const { id } = event.currentTarget.dataset;
    switchToDetail(id);
  };

  return (
    <CSSTransition in timeout={200} classNames="fade" appear>
      <div className="overlay" onClick={closeNoticeModal}>
        {loading && <Loading />}
        <div className="noticeContainer" onClick={stopPropagation}>
          <div className="noticeSideBar">
            <div className="noticeSideBarHeader">
              <div className="noticeSideBarHeader__title">お知らせ一覧</div>
              <FontAwesomeIcon
                icon={['fas', 'plus']}
                className="noticeSideBarHeader__icon"
                onClick={switchToForm}
              />
            </div>
            <div className="noticeSideBarMain">
              {notices.map((notice) => {
                const modifire = Utils.dateIsBefore(Utils.castToDateObject(notice.start_date))
                  ? '' : '--before';
                return (
                  <div
                    key={String(notice.id)}
                    data-id={notice.id}
                    className="noticeList"
                    onClick={onClickList}
                  >
                    <div className={`noticeList__title${modifire}`}>{notice.title}</div>
                    <div className="noticeList__date">{Utils.dateFormatToString(notice.start_date)}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="noticeMain">
            {formShown ? <NoticeForm /> : <NoticeDetail />}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

// For NoticeForm
const mapStateToPropsForForm = (state: RootState) => {
  const { notice } = state;
  return {
    title: notice.title,
    start_date: notice.start_date,
    description: notice.description,
    targetNotice: notice.targetNotice,
  };
};

const mapDispatchToPropsForForm = {
  onChangeTitle: noticeActions.onChangeTitle,
  onChangeStartDate: noticeActions.onChangeStartDate,
  onChangeDescription: noticeActions.onChangeDescription,
  createNotice: noticeActions.createNotice,
  updateNotice: noticeActions.updateNotice,
  loadStartForNotice: noticeActions.loadStartForNotice,
  openConfirm: confirmActions.openConfirm,
  closeConfirm: confirmActions.closeConfirm,
};

const noticeFormConnector = connect(mapStateToPropsForForm, mapDispatchToPropsForForm);

type formPropsFromRedux = ConnectedProps<typeof noticeFormConnector>

const NoticeForm = noticeFormConnector((props: formPropsFromRedux) => {
  const {
    title,
    start_date,
    description,
    targetNotice,
    onChangeTitle,
    onChangeStartDate,
    onChangeDescription,
    createNotice,
    updateNotice,
    loadStartForNotice,
    openConfirm,
    closeConfirm,
  } = props;

  const handleCreate = () => {
    if (start_date === null) {
      openConfirm({
        type: 'error',
        title: failToCreateNotice,
        description: withoutStartDate,
      });
      return;
    }

    const params = { title, start_date, description };

    if (Utils.dateIsBefore(start_date)) {
      openConfirm({
        type: 'ask',
        title: createNoticeText,
        description: confirmDeliverJustNow,
        confirm: () => {
          loadStartForNotice();
          createNotice(params);
          closeConfirm();
        },
      });
    } else {
      loadStartForNotice();
      createNotice(params);
    }
  };

  const handleUpdate = () => {
    if (start_date === null) {
      openConfirm({
        type: 'error',
        title: failToUpdateNotice,
        description: withoutStartDate,
      });
      return;
    }

    const params = {
      id: targetNotice.id,
      title,
      start_date,
      description,
    };

    if (Utils.dateIsBefore(start_date)) {
      openConfirm({
        type: 'ask',
        title: createNoticeText,
        description: confirmDeliverJustNow,
        confirm: () => {
          loadStartForNotice();
          updateNotice(params);
          closeConfirm();
        },
      });
    } else {
      loadStartForNotice();
      updateNotice(params);
    }
  };

  const decideWhetherNewOrEdit = () => {
    if (targetNotice.id === INITIAL_TARGET_ID) {
      return {
        title: '新たにお知らせを配信する',
        buttonText: '作成する',
        onClick: handleCreate,
      };
    }
    return {
      title: 'お知らせを更新する',
      buttonText: '更新する',
      onClick: handleUpdate,
    };
  };

  const formProps = decideWhetherNewOrEdit();

  return (
    <>
      <div className="noticeMain__title">{formProps.title}</div>
      <div className="noticeForm">
        <div className="noticeFormItem">
          <div className="noticeFormItem__label">タイトル</div>
          <input
            className="noticeFormItem__textInput"
            type="text"
            value={title}
            onChange={(event) => onChangeTitle(event.target.value)}
          />
        </div>
        <div className="noticeFormItem">
          <div className="noticeFormItem__label">配信開始日</div>
          <DatePicker
            showTimeSelect
            dateFormat="yyyy/MM/dd HH:mm"
            timeFormat="HH:mm"
            timeIntervals={60}
            selected={start_date}
            onChange={(date) => onChangeStartDate(date)}
            className="noticeFormItem__datePicker"
          />
        </div>
        <div className="noticeFormItem">
          <div className="noticeFormItem__label">内容</div>
          <textarea
            className="noticeFormItem__textArea"
            value={description}
            onChange={(event) => onChangeDescription(event.target.value)}
          />
        </div>
        <button type="button" className="noticeForm__button" onClick={formProps.onClick}>
          {formProps.buttonText}
        </button>
      </div>
    </>
  );
});

// For NoticeDetail
const mapStateToPropsForDetail = (state: RootState) => {
  const { notice } = state;
  return {
    targetNotice: notice.targetNotice,
  };
};

const mapDispatchToPropsForDetail = {
  switchToEditForm: noticeActions.switchToEditForm,
  deleteNotice: noticeActions.deleteNotice,
  loadStartForNotice: noticeActions.loadStartForNotice,
  openConfirm: confirmActions.openConfirm,
  closeConfirm: confirmActions.closeConfirm,
};

const noticeDetailConnector = connect(mapStateToPropsForDetail, mapDispatchToPropsForDetail);

type detailPropsFromRedux = ConnectedProps<typeof noticeDetailConnector>

const NoticeDetail = noticeDetailConnector((props: detailPropsFromRedux) => {
  const {
    targetNotice,
    switchToEditForm,
    deleteNotice,
    loadStartForNotice,
    openConfirm,
    closeConfirm,
  } = props;

  const onClickDeleteIcon = (event: any) => {
    const { id } = event.currentTarget.dataset;
    const params = { id: Number(id) };

    openConfirm({
      type: 'ask',
      title: targetNotice.title,
      description: deleteNoticeText,
      confirm: () => {
        loadStartForNotice();
        deleteNotice(params);
        closeConfirm();
      },
    });
  };

  return (
    <>
      <div className="noticeMain__title">{targetNotice.title}</div>
      <div className="noticeDetail">
        <div className="noticeDetailItems">
          <div className="noticeDetailItems__label">配信開始日</div>
          <div className="noticeDetailItems__date">{Utils.dateFormatToString(targetNotice.start_date)}</div>
        </div>
        <div className="noticeDetailItems">
          <div className="noticeDetailItems__label">内容</div>
          <div className="noticeDetailItems__text">{targetNotice.description}</div>
        </div>
        <div className="noticeDetailActions">
          {!Utils.dateIsBefore(Utils.castToDateObject(targetNotice.start_date)) && (
            <FontAwesomeIcon
              icon={['fas', 'edit']}
              className="noticeDetailActions__edit"
              onClick={switchToEditForm}
            />
          )}
          <FontAwesomeIcon
            icon={['fas', 'trash-alt']}
            className="noticeDetailActions__delete"
            data-id={targetNotice.id}
            onClick={onClickDeleteIcon}
          />
        </div>
      </div>
    </>
  );
});

export default connector(Notice);
