import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup, IconDefinition, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { connect, ConnectedProps } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { RootState } from '../store';
import * as confirmActions from '../store/confirm/actions';

const mapStateToProps = (state: RootState) => {
  const { confirm } = state;
  return {
    type: confirm.type,
    title: confirm.title,
    description: confirm.description,
    confirm: confirm.confirm,
  };
};

const mapDispatchToProps = {
  closeConfirm: confirmActions.closeConfirm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const confirmType = (type: string) => {
  switch (type) {
    case 'success': {
      const iconLookup: IconLookup = { prefix: 'fas', iconName: 'check' };
      return { iconLookup, className: 'confirm__icon--success' };
    }
    case 'error': {
      const iconLookup: IconLookup = { prefix: 'fas', iconName: 'exclamation' };
      return { iconLookup, className: 'confirm__icon--error' };
    }
    case 'ask': {
      const iconLookup: IconLookup = { prefix: 'fas', iconName: 'question' };
      return { iconLookup, className: 'confirm__icon--ask' };
    }
    default: {
      const iconLookup: IconLookup = { prefix: 'fas', iconName: 'exclamation' };
      return { iconLookup, className: 'confirm__icon--error' };
    }
  }
};

const stopPropagation = (event: any) => event.stopPropagation();

const Confirm = (props: PropsFromRedux) => {
  const {
    type,
    title,
    description,
    confirm,
    closeConfirm,
  } = props;

  const iconType = confirmType(type);
  const iconDefinition: IconDefinition = findIconDefinition(iconType.iconLookup);
  const onConfirm = type === 'ask' ? confirm : closeConfirm;
  const modifire = type === 'ask' ? '--ask' : '';
  const classNameForButton = `confirmButton__confirm${modifire}`;

  return (
    <CSSTransition in appear timeout={200} classNames="slide-down-slow">
      <div className="confirmOverlay" onClick={stopPropagation}>
        <div className="confirm">
          {iconType && (
            <div className={iconType.className}>
              <FontAwesomeIcon icon={iconDefinition} />
            </div>
          )}
          <div className="confirmText">
            <div className="confirmText__title">{title}</div>
            <div className="confirmText__description">{description}</div>
          </div>
          <div className="confirmButton">
            <div className={classNameForButton} onClick={onConfirm}>OK</div>
            {type === 'ask'
              && <div className="confirmButton__cancel" onClick={closeConfirm}>Cancel</div>}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default connector(Confirm);
