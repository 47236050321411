export const OPEN_DOCUMENT_MODAL = 'OPEN_DOCUMENT_MODAL';
export const CLOSE_DOCUMENT_MODAL = 'CLOSE_DOCUMENT_MODAL';
export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';

export type Document = {
  id: number
  name: string
  link: string
}

export type DocumentState = {
  modalVisible: boolean
  documents: Document[]
}

type OpenModalAction = {
  type: typeof OPEN_DOCUMENT_MODAL
}

type CloseModalAction = {
  type: typeof CLOSE_DOCUMENT_MODAL
}

type GetAllDocumentsAction = {
  type: typeof GET_ALL_DOCUMENTS
  payload: Document[]
}

export type DocumentActionTypes = OpenModalAction | CloseModalAction | GetAllDocumentsAction;
