import {
  OPEN_FEEDBACK_MODAL,
  CLOSE_FEEDBACK_MODAL,
  GET_ALL_FEEDBACK,
  GET_NEW_FEEDBACK,
  SHOW_FEEDBACK,
  FEEDBACK_LOAD_START,
  FeedbackState,
  FeedbackActionTypes,
} from './types';

const initialCurrentTarget = {
  id: 0,
  body: '',
  created_at: new Date().toISOString(),
  uid: '',
};

const initialState: FeedbackState = {
  modalVisible: false,
  loading: true,
  feedbacks: [],
  currentTarget: initialCurrentTarget,
};

const feedbackReducer = (state = initialState, action: FeedbackActionTypes) => {
  switch (action.type) {
    case OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        modalVisible: true,
      };
    case CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        modalVisible: false,
      };
    case GET_ALL_FEEDBACK:
      return {
        ...state,
        loading: false,
        feedbacks: action.payload,
        currentTarget: action.payload[0],
      };
    case GET_NEW_FEEDBACK: {
      const feedbacks = [...action.payload, ...state.feedbacks];
      return {
        ...state,
        loading: false,
        feedbacks,
        currentTarget: feedbacks[0],
      };
    }
    case SHOW_FEEDBACK: {
      const currentTarget = state.feedbacks.filter((feedback) => (
        feedback.id === action.payload
      ));
      return {
        ...state,
        currentTarget: currentTarget[0],
      };
    }
    case FEEDBACK_LOAD_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
