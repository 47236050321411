import {
  OPEN_DOCUMENT_MODAL,
  CLOSE_DOCUMENT_MODAL,
  GET_ALL_DOCUMENTS,
  DocumentState,
  DocumentActionTypes,
} from './types';

const initialState: DocumentState = { modalVisible: false, documents: [] };

const documentReducer = (state = initialState, action: DocumentActionTypes) => {
  switch (action.type) {
    case OPEN_DOCUMENT_MODAL:
      return {
        ...state,
        modalVisible: true,
      };
    case CLOSE_DOCUMENT_MODAL:
      return {
        ...state,
        modalVisible: false,
      };
    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    default:
      return state;
  }
};

export default documentReducer;
