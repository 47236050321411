import createAxiosInstance from '../../configureAxios';
import { AppDispatch } from '..';
import Utils from '../../utils/utils';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import {
  OPEN_FEEDBACK_MODAL,
  CLOSE_FEEDBACK_MODAL,
  GET_ALL_FEEDBACK,
  GET_NEW_FEEDBACK,
  SHOW_FEEDBACK,
  FEEDBACK_LOAD_START,
} from './types';

export const openFeedbackModal = () => ({
  type: OPEN_FEEDBACK_MODAL,
});

export const closeFeedbackModal = () => ({
  type: CLOSE_FEEDBACK_MODAL,
});

export const getAllFeedback = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/feedback').catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_ALL_FEEDBACK, payload: response.data.feedback.reverse() });
};

export const getNewFeedback = (params: { id: number }) => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.put('/feedback', params).catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_NEW_FEEDBACK, payload: response.data.feedback.reverse() });
};

export const showFeedback = (id: number) => ({
  type: SHOW_FEEDBACK,
  payload: id,
});

export const feedbackLoadStart = () => ({
  type: FEEDBACK_LOAD_START,
});
