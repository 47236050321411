import createAxiosInstance from '../../configureAxios';
import { AppDispatch } from '..';
import Utils from '../../utils/utils';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import { GET_USERS_COUNT } from '../kpi/types';
import {
  OPEN_CUSTOMER_MODAL,
  CLOSE_CUSTOMER_MODAL,
  GET_ALL_CUSTOMERS,
  SEARCH_CUSTOMER,
  Customer,
  SearchParams,
} from './types';

export const openCustomerModal = (customer: Customer) => ({
  type: OPEN_CUSTOMER_MODAL,
  payload: customer,
});

export const closeCustomerModal = () => ({
  type: CLOSE_CUSTOMER_MODAL,
});

export const getAllCustomers = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/customers').catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  const reducer = (currentCount: number, customer: Customer) => (
    customer.agent === '株式会社SNAPSHOT（テスト用）' ? currentCount : currentCount + customer.count
  );

  const userCount = response.data.customers.reduce(reducer, 0);

  dispatch({ type: GET_USERS_COUNT, payload: userCount });
  dispatch({ type: GET_ALL_CUSTOMERS, payload: response.data.customers });
};

export const searchCustomer = (params: SearchParams) => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.post('/customer/search', params).catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: SEARCH_CUSTOMER, payload: response.data.customers });
};
