export const OPEN_NOTICE_MODAL = 'OPEN_NOTICE_MODAL';
export const CLOSE_NOTICE_MODAL = 'CLOSE_NOTICE_MODAL';
export const SWITCH_TO_FORM = 'SWITCH_TO_FORM';
export const SWITCH_TO_DETAIL = 'SWITCH_TO_DETAIL';
export const SWITCH_TO_EDIT_FORM = 'SWITCH_TO_EDIT_FORM';
export const ON_CHANGE_TITLE = 'ON_CHANGE_TITLE';
export const ON_CHANGE_START_DATE = 'ON_CHANGE_START_DATE';
export const ON_CHANGE_DESCRIPTION = 'ON_CHANGE_DESCRIPTION';
export const GET_ALL_NOTICE = 'GET_ALL_NOTICE';
export const CREATE_NOTICE = 'CREATE_NOTICE';
export const UPDATE_NOTICE = 'UPDATE_NOTICE';
export const DELETE_NOTICE = 'DELETE_NOTICE';
export const LOAD_START_FOR_NOTICE = 'LOAD_START_FOR_NOTICE';
export const LOAD_END_FOR_NOTICE = 'LOAD_END_FOR_NOTICE';

export const INITIAL_TARGET_ID = 0;

export type NoticeParams = {
  title: string
  start_date: Date
  description: string
}

export type Notice = {
  id: number
  title: string
  start_date: Date | string
  description: string
}

export type NoticeState = {
  modalVisible: boolean
  formShown: boolean
  title: string
  start_date: Date | null
  description: string
  notices: Notice[]
  targetNotice: Notice
  loading: boolean
}

type OpenNoticeModalAction = {
  type: typeof OPEN_NOTICE_MODAL,
}

type CloseModalAction = {
  type: typeof CLOSE_NOTICE_MODAL
}

type SwitchToFormAction = {
  type: typeof SWITCH_TO_FORM
}

type SwitchToDetailAction = {
  type: typeof SWITCH_TO_DETAIL
  payload: number
}

type SwitchToEditFormAction = {
  type: typeof SWITCH_TO_EDIT_FORM
}

type OnChangeTitleAction = {
  type: typeof ON_CHANGE_TITLE
  payload: string
}

type OnChangeStartDateAction = {
  type: typeof ON_CHANGE_START_DATE
  payload: Date | null
}

type OnChangeDescriptionAction = {
  type: typeof ON_CHANGE_DESCRIPTION
  payload: string
}

type GetAllNoticeAction = {
  type: typeof GET_ALL_NOTICE
  payload: Notice[]
}

type CreateNoticeAction = {
  type: typeof CREATE_NOTICE
  payload: Notice
}

type UpdateNoticeAction = {
  type: typeof UPDATE_NOTICE
  payload: Notice
}

type DeleteNoticeAction = {
  type: typeof DELETE_NOTICE
  payload: number
}

type LoadStartForNotice = {
  type: typeof LOAD_START_FOR_NOTICE
}

type LoadEndForNotice = {
  type: typeof LOAD_END_FOR_NOTICE
}

export type NoticeActionTypes =
  OpenNoticeModalAction
  | CloseModalAction
  | SwitchToFormAction
  | SwitchToDetailAction
  | SwitchToEditFormAction
  | OnChangeTitleAction
  | OnChangeStartDateAction
  | OnChangeDescriptionAction
  | GetAllNoticeAction
  | CreateNoticeAction
  | UpdateNoticeAction
  | DeleteNoticeAction
  | LoadStartForNotice
  | LoadEndForNotice
