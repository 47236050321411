import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect, ConnectedProps } from 'react-redux';

import firebase from '../firebase';
import { RootState } from '../store';
import * as confirmActions from '../store/confirm/actions';
import * as sessionActions from '../store/session/actions';
import * as settingActions from '../store/setting/actions';
import * as menuActions from '../store/menu/actions';
import EditEmailForm from './EditEmailForm';
import {
  failToSignOutTitle,
  confirmSendResetPasswordEmail,
  sentResetPasswordEmailText,
  failToSendResetPasswordEmail,
  confirmSignOut,
} from '../utils/text';

const mapStateToProps = (state: RootState) => {
  const { session, setting } = state;
  return {
    email: session.email,
    editEmailFormVisible: setting.editEmailFormVisible,
  };
};

const mapDispatchToProps = {
  openConfirm: confirmActions.openConfirm,
  closeConfirm: confirmActions.closeConfirm,
  onChangeEmail: sessionActions.onChangeEmail,
  closeSettingModal: settingActions.closeSettingModal,
  toggleEditEmailForm: settingActions.toggleEditEmailForm,
  signOut: sessionActions.signOut,
  closeMenu: menuActions.closeMenu,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const stopPropagation = (event: any) => event.stopPropagation();

const Setting = (props: PropsFromRedux) => {
  const {
    email,
    openConfirm,
    closeConfirm,
    closeSettingModal,
    signOut,
    closeMenu,
  } = props;

  const sendPasswordResetEmail = () => {
    closeConfirm();
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        openConfirm({
          type: 'success',
          title: sentResetPasswordEmailText,
          description: '',
        });
      })
      .catch(() => {
        openConfirm({
          type: 'error',
          title: failToSendResetPasswordEmail,
          description: '',
        });
      });
  };

  const handleResetPassword = () => {
    openConfirm({
      type: 'ask',
      title: confirmSendResetPasswordEmail,
      description: '',
      confirm: sendPasswordResetEmail,
    });
  };

  const handleSignOut = () => {
    firebase.auth().signOut()
      .then(() => {
        openConfirm({
          type: 'ask',
          title: confirmSignOut,
          description: '',
          confirm: () => {
            closeSettingModal();
            closeMenu();
            closeConfirm();
            signOut();
          },
        });
      })
      .catch(() => {
        openConfirm({
          type: 'error',
          title: failToSignOutTitle,
          description: '',
        });
      });
  };

  return (
    <CSSTransition in timeout={200} classNames="fade" appear>
      <div className="overlay" onClick={closeSettingModal}>
        <div className="settingContainer" onClick={stopPropagation}>
          <div className="settingHeader">
            <div className="settingHeader__title">設定</div>
          </div>
          <div className="settingList">
            <EditEmailForm />
            <div className="settingPassword" onClick={handleResetPassword}>
              <div className="settingPassword__label">パスワード変更</div>
            </div>
            <div className="setting__signout" onClick={handleSignOut}>サインアウト</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default connector(Setting);
