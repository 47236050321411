import createAxiosInstance from '../../configureAxios';
import Utils from '../../utils/utils';
import { AppDispatch } from '..';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import {
  SALES_SEARCH_LOAD_START,
  SALES_SEARCH_LOAD_END,
  SHOW_SALES,
  HIDE_SALES,
  ON_CHANGE_SALES_SEARCH_FROM,
  ON_CHANGE_SALES_SEARCH_TO,
  SEARCH_SALES_HISTORY,
  SalesHistoryParams,
} from './types';

import { failToSearchSalesHistory, invalidDateForSearchSales } from '../../utils/text';

export const showSales = () => ({
  type: SHOW_SALES,
});

export const hideSales = () => ({
  type: HIDE_SALES,
});

export const onChangeSalesSearchFrom = (payload: Date | null) => ({
  type: ON_CHANGE_SALES_SEARCH_FROM,
  payload,
});

export const onChangeSalesSearchTo = (payload: Date | null) => ({
  type: ON_CHANGE_SALES_SEARCH_TO,
  payload,
});

export const seachSalesHistory = (params: SalesHistoryParams) => async (dispatch: AppDispatch) => {
  dispatch({ type: SALES_SEARCH_LOAD_START });

  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.post('/sales/search', params).catch((error) => error.response);

  if (response.status === 400) {
    const confirmProps = {
      type: 'error' as 'error',
      title: failToSearchSalesHistory,
      description: invalidDateForSearchSales,
    };

    dispatch({ type: OPEN_CONFIRM, payload: confirmProps });
    dispatch({ type: SALES_SEARCH_LOAD_END });
    return;
  }

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    dispatch({ type: SALES_SEARCH_LOAD_END });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    dispatch({ type: SALES_SEARCH_LOAD_END });
    return;
  }

  const regexDateFrom = Utils.formatMonthYear(params.from);
  const regexDateTo = Utils.formatMonthYear(params.to);
  const salesHistoryRange = `${regexDateFrom}~${regexDateTo}`;

  dispatch({
    type: SEARCH_SALES_HISTORY,
    payload: { data: response.data.sales, salesHistoryRange },
  });
};
