import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import Document from './Document';
import AccountForm from './AccountForm';
import Setting from './Setting';
import Notice from './Notice';
import Feedback from './Feedback';
import * as documentActions from '../store/document/actions';
import * as createAccountActions from '../store/createAccount/actions';
import * as settingActions from '../store/setting/actions';
import * as noticeActions from '../store/notice/actions';
import * as feedbackActions from '../store/feedback/actions';
import * as menuActions from '../store/menu/actions';

const mapStateToProps = (state: RootState) => {
  const {
    document, createAccount, setting, notice, feedback, menu,
  } = state;

  return {
    documentModalVisible: document.modalVisible,
    createAccountModalVisible: createAccount.visible,
    settingModalVisible: setting.settingModalVisible,
    noticeModalVisible: notice.modalVisible,
    feedbackModalVisible: feedback.modalVisible,
    inProp: menu.inProp,
  };
};

const mapDispatchToProps = {
  openDocumentModal: documentActions.openDocumentModal,
  openCreateAccountModal: createAccountActions.openCreateAccountModal,
  openSettingModal: settingActions.openSettingModal,
  closeMenu: menuActions.closeMenu,
  transitionExit: menuActions.transitionExit,
  openNoticeModal: noticeActions.openNoticeModal,
  openFeedbackModal: feedbackActions.openFeedbackModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const Menu = (props: PropsFromRedux) => {
  const {
    closeMenu,
    transitionExit,
    openDocumentModal,
    openCreateAccountModal,
    openSettingModal,
    openNoticeModal,
    openFeedbackModal,
    documentModalVisible,
    createAccountModalVisible,
    settingModalVisible,
    noticeModalVisible,
    feedbackModalVisible,
    inProp,
  } = props;

  return (
    <CSSTransition in={inProp} appear timeout={300} classNames="menuContainer" onExited={closeMenu}>
      <div className="menuContainer">
        <div className="menuHeader">
          <div className="menuHeader__text">メニュー</div>
          <FontAwesomeIcon icon={['fas', 'times']} className="menuHeader__icon" onClick={transitionExit} />
        </div>
        <div className="menuBody">
          <div className="menuList" onClick={openDocumentModal}>
            <FontAwesomeIcon icon={['far', 'file-alt']} className="menuList__icon" />
            <div className="menuList__label">各種資料</div>
          </div>
          <div className="menuList" onClick={openCreateAccountModal}>
            <FontAwesomeIcon icon={['fas', 'user-plus']} className="menuList__icon" />
            <div className="menuList__label">アカウント作成</div>
          </div>
          <div className="menuList" onClick={openSettingModal}>
            <FontAwesomeIcon icon={['fas', 'cog']} className="menuList__icon" />
            <div className="menuList__label">設定</div>
          </div>
          <div className="menuList" onClick={openNoticeModal}>
            <FontAwesomeIcon icon={['far', 'comment-alt']} className="menuList__icon" />
            <div className="menuList__label">お知らせ配信</div>
          </div>
          <div className="menuList" onClick={openFeedbackModal}>
            <FontAwesomeIcon icon={['far', 'envelope']} className="menuList__icon" />
            <div className="menuList__label">フィードバック</div>
          </div>
        </div>
        {documentModalVisible && <Document />}
        {settingModalVisible && <Setting />}
        {createAccountModalVisible && <AccountForm />}
        {noticeModalVisible && <Notice />}
        {feedbackModalVisible && <Feedback />}
      </div>
    </CSSTransition>
  );
};

export default connector(Menu);
