import createAxiosInstance from '../../configureAxios';
import Utils from '../../utils/utils';
import { AppDispatch } from '..';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import { GET_THIS_MONTH_ALERT, GET_NEXT_MONTH_ALERT } from './types';

export const getThisMonthAlert = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/alert', { params: { 'period': 'this-month' } })
    .catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_THIS_MONTH_ALERT, payload: response.data.customers });
};

export const getNextMonthAlert = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/alert', { params: { 'period': 'next-month' } })
    .catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_NEXT_MONTH_ALERT, payload: response.data.customers });
};
