export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export const GET_ALL_FEEDBACK = 'GET_ALL_FEEDBACK';
export const GET_NEW_FEEDBACK = 'GET_NEW_FEEDBACK';
export const SHOW_FEEDBACK = 'SHOW_FEEDBACK';
export const FEEDBACK_LOAD_START = 'FEEDBACK_LOAD_START';

export type Feedback = {
  id: number
  body: string
  created_at: string
  uid: string
}

export type FeedbackState = {
  modalVisible: boolean
  loading: boolean
  feedbacks: Feedback[]
  currentTarget: Feedback
}

type OpenFeedbackModalAction = {
  type: typeof OPEN_FEEDBACK_MODAL
}

type CloseFeedbackModalAction = {
  type: typeof CLOSE_FEEDBACK_MODAL
}

type GetAllFeedbackAction = {
  type: typeof GET_ALL_FEEDBACK
  payload: Feedback[]
}

type GetNewFeedbackAction = {
  type: typeof GET_NEW_FEEDBACK
  payload: Feedback[]
}

type ShowFeedbackAction = {
  type: typeof SHOW_FEEDBACK
  payload: number
}

type FeedbackLoadStartAction = {
  type: typeof FEEDBACK_LOAD_START
}

export type FeedbackActionTypes =
  GetAllFeedbackAction
  | GetNewFeedbackAction
  | FeedbackLoadStartAction
  | OpenFeedbackModalAction
  | CloseFeedbackModalAction
  | ShowFeedbackAction
