import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import { Agent } from '../store/agent/types';
import { orderOptions, DEFAULT_ORDER } from '../store/search/types';
import * as agentActions from '../store/agent/actions';
import * as customerActions from '../store/customer/actions';
import * as searchActions from '../store/search/actions';
import Utils from '../utils/utils';

const mapStateToProps = (state: RootState) => {
  const { agent, customer, search } = state;
  return {
    agents: agent.agents,
    customers: customer.customers,
    searchByNameVisible: search.searchByNameVisible,
    orderByDateVisible: search.orderByDateVisible,
    orderByCountVisible: search.orderByCountVisible,
    filterByAgentVisible: search.filterByAgentVisible,
    name: search.name,
    orderByDate: search.orderByDate,
    orderByCount: search.orderByCount,
    selectedAgent: search.selectedAgent,
  };
};

const mapDispatchToProps = {
  getAllAgent: agentActions.getALlAgents,
  openCustomerModal: customerActions.openCustomerModal,
  getAllCustomer: customerActions.getAllCustomers,
  searchCustomer: customerActions.searchCustomer,
  closeAllForm: searchActions.closeAllForm,
  toggleSearchByName: searchActions.toggleSearchByName,
  toggleOrderByDate: searchActions.toggleOrderByDate,
  toggleOrderByCount: searchActions.toggleOrderByCount,
  toggleFilterByAgent: searchActions.toggleFilterByAgent,
  onChangeName: searchActions.onChangeName,
  onChangeDateOrder: searchActions.onChangeDateOrder,
  onChangeCountOrder: searchActions.onChangeCountOrder,
  onChangeAgent: searchActions.onChangeAgent,
  clearDateOrder: searchActions.clearDateOrder,
  clearCountOrder: searchActions.clearCountOrder,
  clearAgentFilter: searchActions.clearAgentFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OrderProps = {
  selected: number
  onChange: (payload: number) => void
  clear: () => void
}

type FilterByAgentProps = {
  agents: Agent[]
  selected: number
  onChange: (payload: number) => void
  clear: () => void
}

type PropsFromRedux = ConnectedProps<typeof connector>

const Order = (props: OrderProps) => {
  const { selected, onChange, clear } = props;
  return (
    <CSSTransition in appear timeout={50} classNames="slide-down">
      <div className="searchContainer">
        {selected !== DEFAULT_ORDER && (
          <div className="searchClear" onClick={clear}>並び替えをクリア</div>
        )}
        <div className="searchLabel">並び替え</div>
        <select
          className="searchSelect"
          value={selected}
          onChange={(event) => onChange(Number(event.target.value))}
        >
          {orderOptions.map((order) => (
            <option key={order.label} value={order.value}>{order.label}</option>))}
        </select>
        <div className="selectboxDivide" />
      </div>
    </CSSTransition>
  );
};

const FilterByAgent = (props: FilterByAgentProps) => {
  const {
    agents, selected, onChange, clear,
  } = props;

  return (
    <CSSTransition in appear timeout={50} classNames="slide-down">
      <div className="searchContainer--agent">
        {selected !== DEFAULT_ORDER && (
          <div className="searchClear" onClick={clear}>絞り込みをクリア</div>
        )}
        <div className="searchLabel">絞り込み</div>
        <select
          className="searchSelect"
          value={selected}
          onChange={(event) => onChange(Number(event.target.value))}
        >
          <option value={0} aria-label="select" />
          {agents.map((agent) => <option key={agent.id} value={agent.id}>{agent.name}</option>)}
        </select>
        <div className="selectboxDivide" />
      </div>
    </CSSTransition>
  );
};

const IndexCustomer = (props: PropsFromRedux) => {
  const {
    agents,
    getAllAgent,
    customers,
    searchByNameVisible,
    orderByDateVisible,
    orderByCountVisible,
    filterByAgentVisible,
    name,
    orderByDate,
    orderByCount,
    selectedAgent,
    getAllCustomer,
    searchCustomer,
    openCustomerModal,
    closeAllForm,
    toggleSearchByName,
    toggleOrderByDate,
    toggleOrderByCount,
    toggleFilterByAgent,
    onChangeName,
    onChangeDateOrder,
    onChangeCountOrder,
    onChangeAgent,
    clearDateOrder,
    clearCountOrder,
    clearAgentFilter,
  } = props;

  const classNameForName = Utils.activeClassName(
    'indexCustomerHeader__icon', searchByNameVisible,
  );

  const classNameForDate = Utils.activeClassName(
    'indexCustomerHeader__label', orderByDate !== DEFAULT_ORDER,
  );

  const classNameForCount = Utils.activeClassName(
    'indexCustomerHeader__label', orderByCount !== DEFAULT_ORDER,
  );

  const classNameForAgent = Utils.activeClassName(
    'indexCustomerHeader__label', selectedAgent !== 0,
  );

  const isFirst = useRef(true);

  useEffect(() => {
    getAllCustomer();
    getAllAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
    } else {
      const params = {
        name,
        order_by_date: orderByDate,
        order_by_count: orderByCount,
        agent_id: selectedAgent,
      };
      searchCustomer(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    orderByDate,
    orderByCount,
    selectedAgent,
  ]);

  return (
    <div className="indexCustomerWrapper">
      {orderByCountVisible || filterByAgentVisible || orderByDateVisible
        ? <div className="searchOverlay" onClick={closeAllForm} /> : null}
      <div className="indexCustomer">
        <div className="indexCustomerHeader">
          <div className="indexCustomerHeader__name">
            <FontAwesomeIcon
              icon={['fas', 'search']}
              className={classNameForName}
              onClick={toggleSearchByName}
            />
            {searchByNameVisible ? (
              <input
                type="text"
                className="indexCustomerHeader__search"
                placeholder="企業名を入力"
                value={name}
                onChange={(event) => onChangeName(event.target.value)}
              />
            ) : '企業名'}
          </div>

          <div className="indexCustomerHeader__from">
            {orderByDateVisible && (
              <Order
                selected={orderByDate}
                onChange={onChangeDateOrder}
                clear={clearDateOrder}
              />
            )}
            <span className={classNameForDate} onClick={toggleOrderByDate}>契約日</span>
          </div>

          <div className="indexCustomerHeader__count">
            {orderByCountVisible && (
              <Order
                selected={orderByCount}
                onChange={onChangeCountOrder}
                clear={clearCountOrder}
              />
            )}
            <span className={classNameForCount} onClick={toggleOrderByCount}>利用ユーザ数</span>
          </div>

          <div className="indexCustomerHeader__agent">
            {filterByAgentVisible && (
              <FilterByAgent
                agents={agents}
                selected={selectedAgent}
                onChange={onChangeAgent}
                clear={clearAgentFilter}
              />
            )}
            <span className={classNameForAgent} onClick={toggleFilterByAgent}>代理店名</span>
          </div>
        </div>
        <div className="indexCustomerList">
          {customers.map((customer) => (
            <div
              key={customer.id}
              className="indexCustomerRow"
              onClick={() => openCustomerModal(customer)}
            >
              <div className="indexCustomerRow__name">{customer.name}</div>
              <div className="indexCustomerRow__from">{customer.from}</div>
              <div className="indexCustomerRow__count">{customer.count}</div>
              <div className="indexCustomerRow__agent">{customer.agent}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connector(IndexCustomer);
