import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { RootState } from '../store';
import * as documentActions from '../store/document/actions';

const mapStateToProps = (state: RootState) => {
  const { document } = state;
  return {
    documents: document.documents,
  };
};

const mapDispatchToProps = {
  closeDocumentModal: documentActions.closeDocumentModal,
  getAllDocuments: documentActions.getAllDocuments,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const Document = (props: PropsFromRedux) => {
  const { documents, closeDocumentModal, getAllDocuments } = props;

  useEffect(() => {
    getAllDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopPropagation = (event: any) => event.stopPropagation();

  return (
    <CSSTransition in timeout={200} classNames="fade" appear>
      <div className="overlay" onClick={closeDocumentModal}>
        <div className="document" onClick={stopPropagation}>
          <div className="documentHeader">
            <div className="documentHeader__title">各種資料</div>
          </div>
          <div className="documentIndex">
            {documents.map((document) => (
              <div className="documentRow" key={document.id}>
                <a
                  href={`https://agency.chick-tag.jp/${document.link}`}
                  className="documentRow__name"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {document.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default connector(Document);
