import {
  ON_CHANGE_SESSION_EMAIL,
  ON_CHANGE_SESSION_PASSWORD,
  SIGN_IN,
  SIGN_OUT,
  LOAD_START,
  LOAD_END,
  RELOAD_END,
} from './types';

export const onChangeEmail = (payload: string) => ({
  type: ON_CHANGE_SESSION_EMAIL,
  payload,
});

export const onChangePassword = (payload: string) => ({
  type: ON_CHANGE_SESSION_PASSWORD,
  payload,
});

export const signIn = () => ({
  type: SIGN_IN,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const loadStart = () => ({
  type: LOAD_START,
});

export const loadEnd = () => ({
  type: LOAD_END,
});

export const reloadEnd = () => ({
  type: RELOAD_END,
});
