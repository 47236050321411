import createAxiosInstance from '../../configureAxios';
import Utils from '../../utils/utils';
import { AppDispatch } from '..';
import { failToCreateNotice, failToUpdateNotice, failToDeleteNotice } from '../../utils/text';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import {
  OPEN_NOTICE_MODAL,
  CLOSE_NOTICE_MODAL,
  SWITCH_TO_FORM,
  SWITCH_TO_DETAIL,
  SWITCH_TO_EDIT_FORM,
  ON_CHANGE_TITLE,
  ON_CHANGE_START_DATE,
  ON_CHANGE_DESCRIPTION,
  GET_ALL_NOTICE,
  CREATE_NOTICE,
  UPDATE_NOTICE,
  DELETE_NOTICE,
  LOAD_START_FOR_NOTICE,
  LOAD_END_FOR_NOTICE,
  NoticeParams,
} from './types';

export const openNoticeModal = () => ({
  type: OPEN_NOTICE_MODAL,
});

export const closeNoticeModal = () => ({
  type: CLOSE_NOTICE_MODAL,
});

export const switchToForm = () => ({
  type: SWITCH_TO_FORM,
});

export const switchToDetail = (payload: number) => ({
  type: SWITCH_TO_DETAIL,
  payload,
});

export const switchToEditForm = () => ({
  type: SWITCH_TO_EDIT_FORM,
});

export const onChangeTitle = (payload: string) => ({
  type: ON_CHANGE_TITLE,
  payload,
});

export const onChangeStartDate = (payload: Date | null) => ({
  type: ON_CHANGE_START_DATE,
  payload,
});

export const onChangeDescription = (payload: string) => ({
  type: ON_CHANGE_DESCRIPTION,
  payload,
});

export const getAllNotice = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/notices').catch((error) => error.response);

  dispatch({ type: LOAD_END_FOR_NOTICE });

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_ALL_NOTICE, payload: response.data.notices });
};

export const createNotice = (params: NoticeParams) => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.post('/notices', params).catch((error) => error.response);

  dispatch({ type: LOAD_END_FOR_NOTICE });

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  if (response.data.errors === undefined) {
    dispatch({ type: CREATE_NOTICE, payload: response.data });
    return;
  }

  dispatch({
    type: OPEN_CONFIRM,
    payload: {
      type: 'error',
      title: failToCreateNotice,
      description: response.data.errors.join('\n'),
      confirm: () => dispatch({ type: LOAD_END_FOR_NOTICE }),
    },
  });
};

export const updateNotice = (params: NoticeParams & { id: number }) => (
  async (dispatch: AppDispatch) => {
    const token = await Utils.getToken();
    const axios = createAxiosInstance(token);

    const response = await axios.put('/notices', params).catch((error) => error.response);

    dispatch({ type: LOAD_END_FOR_NOTICE });

    if (response.status === 401) {
      dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
      return;
    }

    if (response === undefined || response.status !== 200) {
      dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
      return;
    }

    if (response.data.errors === undefined) {
      dispatch({ type: UPDATE_NOTICE, payload: response.data });
      return;
    }

    dispatch({
      type: OPEN_CONFIRM,
      payload: {
        type: 'error',
        title: failToUpdateNotice,
        description: response.data.errors.join('\n'),
      },
    });
  }
);

export const deleteNotice = (params: { id: number }) => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.delete('/notices', { params }).catch((error) => error.response);

  dispatch({ type: LOAD_END_FOR_NOTICE });

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  if (response.data.result) {
    dispatch({ type: DELETE_NOTICE, payload: params.id });
    return;
  }

  dispatch({
    type: OPEN_CONFIRM,
    payload: {
      type: 'error',
      title: failToDeleteNotice,
      description: response.data.error,
    },
  });
};

export const loadStartForNotice = () => ({
  type: LOAD_START_FOR_NOTICE,
});

export const loadEndForNotice = () => ({
  type: LOAD_END_FOR_NOTICE,
});
