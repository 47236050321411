import {
  GET_THIS_MONTH_ALERT,
  GET_NEXT_MONTH_ALERT,
  AlertState,
  AlertActionType,
} from './types';

const initialState: AlertState = { thisMonthAlert: [], nextMonthAlert: [] };

const alertReducer = (state = initialState, action: AlertActionType) => {
  switch (action.type) {
    case GET_THIS_MONTH_ALERT:
      return {
        ...state,
        thisMonthAlert: action.payload,
      };
    case GET_NEXT_MONTH_ALERT:
      return {
        ...state,
        nextMonthAlert: action.payload,
      };
    default:
      return state;
  }
};

export default alertReducer;
