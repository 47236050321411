import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import * as feedbackActions from '../store/feedback/actions';
import Loading from './Loading';
import Utils from '../utils/utils';

const mapStateToProps = (state: RootState) => {
  const { feedback } = state;
  return {
    loading: feedback.loading,
    feedbacks: feedback.feedbacks,
    currentTarget: feedback.currentTarget,
  };
};

const mapDispatchToProps = {
  closeFeedbackModal: feedbackActions.closeFeedbackModal,
  getAllFeedback: feedbackActions.getAllFeedback,
  getNewFeedback: feedbackActions.getNewFeedback,
  showFeedback: feedbackActions.showFeedback,
  feedbackLoadStart: feedbackActions.feedbackLoadStart,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const stopPropagation = (event: any) => event.stopPropagation();

const Feedback = (props: PropsFromRedux) => {
  const {
    loading,
    feedbacks,
    currentTarget,
    closeFeedbackModal,
    getAllFeedback,
    getNewFeedback,
    showFeedback,
    feedbackLoadStart,
  } = props;

  useEffect(() => {
    getAllFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickUpdate = () => {
    feedbackLoadStart();
    const params = { id: feedbacks[0].id };
    getNewFeedback(params);
  };

  return (
    <CSSTransition in timeout={200} classNames="fade" appear>
      <div className="overlay" onClick={closeFeedbackModal}>
        {loading && <Loading />}
        <div className="feedbackContainer" onClick={stopPropagation}>
          <div className="feedbackSideBar">
            <div className="feedbackSideBarHeader">
              <div className="feedbackSideBarHeader__title">全ての投稿</div>
              <FontAwesomeIcon
                icon={['fas', 'redo-alt']}
                className="feedbackSideBarHeader__icon"
                onClick={onClickUpdate}
              />
            </div>
            <div className="feedbackSideBarMain">
              {feedbacks.map((feedback) => (
                <div
                  key={feedback.id}
                  data-id={feedback.id}
                  className="feedbackList"
                  onClick={(event) => showFeedback(Number(event.currentTarget.dataset.id))}
                >
                  <div className="feedbackList__content">{feedback.body}</div>
                  <div className="feedbackList__senderInfo">
                    UID:
                    <span>{feedback.uid}</span>
                  </div>
                  <div className="feedbackList__senderInfo">
                    投稿日:
                    <span>{Utils.dateFormatToStringShort(feedback.created_at)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="feedbackMain">
            <div className="feedbackMain__title">ユーザーからのご意見・ご感想</div>
            <div className="feedbackItems">
              <div className="feedbackItems__content">{currentTarget.body}</div>
              <div className="feedbackItems__senderInfo">
                UID:
                <span>{currentTarget.uid}</span>
              </div>
              <div className="feedbackItems__senderInfo">
                投稿日:
                <span>{Utils.dateFormatToStringShort(currentTarget.created_at)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default connector(Feedback);
