export const SALES_SEARCH_LOAD_START = 'SALES_SEARCH_LOAD_START';
export const SALES_SEARCH_LOAD_END = 'SALES_SEARCH_LOAD_END';
export const SHOW_SALES = 'SHOW_SALES';
export const HIDE_SALES = 'HIDE_SALES';
export const ON_CHANGE_SALES_SEARCH_FROM = 'ON_CHANGE_SALES_SEARCH_FROM';
export const ON_CHANGE_SALES_SEARCH_TO = 'ON_CHANGE_SALES_SEARCH_TO';
export const SEARCH_SALES_HISTORY = 'SEARCH_SALES_HISTORY';
export const UNIT_PRICE = 500;

export type SalesHistoryParams = {
  from: Date,
  to: Date,
};

export type SalesHistory = {
  date: string
  amount: number
  customer_count: number
}

export type SalesState = {
  isLoading: boolean
  isSalesShown: boolean
  selectedDateFrom: Date | null
  selectedDateTo: Date | null
  salesHistories: SalesHistory[]
  salesHistoryRange: string
  totalSales: string | null
  totalCustomerCount: number | null
}

type SalesSearchLoadStart = {
  type: typeof SALES_SEARCH_LOAD_START
}

type SalesSearchLoadEnd = {
  type: typeof SALES_SEARCH_LOAD_END
}

type ShowSalesAction = {
  type: typeof SHOW_SALES
}

type HideSalesAction = {
  type: typeof HIDE_SALES
}

type OnChangeSalesSearchFromAction = {
  type: typeof ON_CHANGE_SALES_SEARCH_FROM
  payload: Date | null
}

type OnChangeSalesSearchToAction = {
  type: typeof ON_CHANGE_SALES_SEARCH_TO
  payload: Date | null
}

type SearchSalesHistoryAction = {
  type: typeof SEARCH_SALES_HISTORY
  payload: { data: SalesHistory[], salesHistoryRange: string }
};

export type SalesActionTypes =
  ShowSalesAction
  | SalesSearchLoadStart
  | SalesSearchLoadEnd
  | HideSalesAction
  | OnChangeSalesSearchFromAction
  | OnChangeSalesSearchToAction
  | SearchSalesHistoryAction
