export const GET_CUSTOMERS_COUNT = 'GET_CUSTOMERS_COUNT';
export const GET_RECONTRACT_THIS_MONTH_COUNT = 'GET_RECONTRACT_THIS_MONTH_COUNT';
export const GET_RECONTRACT_NEXT_MONTH_COUNT = 'GET_RECONTRACT_NEXT_MONTH_COUNT';
export const GET_RECONTRACT_PERCENTAGE = 'GET_RECONTRACT_PERCENTAGE';
export const GET_USERS_COUNT = 'GET_USERS_COUNT';
export const CUSTOMERS_COUNT = '契約社数';
export const RECONTRACT_THIS_MONTH_COUNT = '当月更新数';
export const RECONTRACT_NEXT_MONTH_COUNT = '翌月更新数';
export const RECONTRACT_PERCENTAGE = '更新率';
export const USERS_COUNT = '利用ユーザ数';

export const data: KPITypes[] = [
  CUSTOMERS_COUNT,
  RECONTRACT_THIS_MONTH_COUNT,
  RECONTRACT_NEXT_MONTH_COUNT,
  RECONTRACT_PERCENTAGE,
  USERS_COUNT,
];

export type KPIState = {
  customersCount: number
  recontractThisMonthCount: number
  recontractNextMonthCount: number
  recontractPercentage: number
  usersCount: number
}

type GetCustomerCountAction = {
  type: typeof GET_CUSTOMERS_COUNT
  payload: number
}

type GetRecontractThisMonthCountAction = {
  type: typeof GET_RECONTRACT_THIS_MONTH_COUNT
  payload: number
}

type GetRecontractNextMonthCountAction = {
  type: typeof GET_RECONTRACT_NEXT_MONTH_COUNT
  payload: number
}

type GetRecontractPercentageAction = {
  type: typeof GET_RECONTRACT_PERCENTAGE
  payload: number
}

type GetUsersCount = {
  type: typeof GET_USERS_COUNT
  payload: number
}

export type KPITypes =
  typeof CUSTOMERS_COUNT
  | typeof RECONTRACT_THIS_MONTH_COUNT
  | typeof RECONTRACT_NEXT_MONTH_COUNT
  | typeof RECONTRACT_PERCENTAGE
  | typeof USERS_COUNT

export type KPIActiontypes =
  GetCustomerCountAction
  | GetRecontractThisMonthCountAction
  | GetRecontractNextMonthCountAction
  | GetRecontractPercentageAction
  | GetUsersCount
