import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import * as confirmActions from '../store/confirm/actions';
import * as sessionActions from '../store/session/actions';
import * as settingActions from '../store/setting/actions';
import firebase from '../firebase';
import i18n from '../utils/i18n';
import { failToUpdateEmail } from '../utils/text';

const mapStateToProps = (state: RootState) => {
  const { session, setting } = state;
  return {
    email: session.email,
    editEmailFormVisible: setting.editEmailFormVisible,
  };
};

const mapDispatchToProps = {
  openConfirm: confirmActions.openConfirm,
  onChangeEmail: sessionActions.onChangeEmail,
  toggleEditEmailForm: settingActions.toggleEditEmailForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const EditEmailForm = (props: PropsFromRedux) => {
  const {
    email,
    toggleEditEmailForm,
    editEmailFormVisible,
    onChangeEmail,
    openConfirm,
  } = props;

  useEffect(() => {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      const currentUserEmail = currentUser.email !== null ? currentUser.email : '';
      onChangeEmail(currentUserEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateEmail = () => {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      currentUser.updateEmail(email)
        .then(toggleEditEmailForm)
        .catch((error) => {
          const i18nMessage = i18n.firebaseErrors(error.code);
          openConfirm({
            type: 'error',
            title: failToUpdateEmail,
            description: i18nMessage,
          });
        });
    }
  };

  return (
    <div className="setting">
      <div className="setting__label">メールアドレス</div>
      {editEmailFormVisible ? (
        <div className="editAccount">
          <input
            type="text"
            className="editAccount__textInput"
            placeholder="Email"
            value={email}
            onChange={(event) => onChangeEmail(event.target.value)}
          />
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            className="editAccount__submit"
            onClick={updateEmail}
          />
        </div>
      ) : (
        <>
          <div className="setting__text">{email}</div>
          <FontAwesomeIcon icon={['fas', 'edit']} className="setting__edit" onClick={toggleEditEmailForm} />
        </>
      )}
    </div>
  );
};

export default connector(EditEmailForm);
