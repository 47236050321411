import firebase from '../firebase';

export default class Utils {
  static activeClassName(baseClass: string, condition: boolean) {
    const className = condition ? `${baseClass}--active` : baseClass;
    return className;
  }

  static buildRequestURL(endPoint: string) {
    // const url = `http://192.168.2.13/customer-management-API/public${endPoint}`;
    const url = `https://stg-customer-api.chick-tag.jp${endPoint}`;
    return url;
  }

  static async getToken() {
    const { currentUser } = firebase.auth();
    if (currentUser === null) {
      return '';
    }

    const token = await currentUser.getIdToken(false);
    return token;
  }

  static dateFormatToStringShort(date: Date | string) {
    if (typeof date === 'string') {
      const [regexStringDate] = date.split(' ');
      return regexStringDate.replace(/-/g, '/');
    }
    const [regexDate] = date.toISOString().split('T');
    return regexDate.replace(/-/g, '/');
  }

  static dateFormatToString(date: Date | string) {
    if (typeof date === 'string') {
      const [splitStringDate, splitStringTime] = date.split(' ');
      const regexDate = splitStringDate.replace(/-/g, '/');
      const regexTime = splitStringTime.slice(0, 5);
      return `${regexDate} ${regexTime}`;
    }
    const [stringDate, stringTime] = date.toISOString().split('T');
    const regexDate = stringDate.replace(/-/g, '/');
    const regexTime = stringTime.slice(0, 5);
    return `${regexDate} ${regexTime}`;
  }

  static dateIsBefore(date: Date, targetDate: Date = new Date()) {
    return targetDate > date;
  }

  static castToDateObject(date: Date | string) {
    if (typeof date === 'string') {
      return new Date(date.replace(/ /, 'T'));
    }
    return date;
  }

  static formatMonthYear(date: Date) {
    const dateString = date.toISOString().split('T')[0];
    const regexDate = dateString.replace(/-/g, '/');
    return regexDate.slice(0, 7);
  }
}
