export const OPEN_SETTING_MODAL = 'OPEN_SETTING_MODAL';
export const CLOSE_SETTING_MODAL = 'CLOSE_SETTING_MODAL';
export const TOGGLE_EDIT_EMAIL_FORM = 'TOGGLE_EDIT_EMAIL_FORM';

export type SettingState = {
  settingModalVisible: boolean
  editEmailFormVisible: boolean
}

type OpenSettingModalAction = {
  type: typeof OPEN_SETTING_MODAL
}

type CloseSettingModalAction = {
  type: typeof CLOSE_SETTING_MODAL
}

type ToggleEditEmailFormAction = {
  type: typeof TOGGLE_EDIT_EMAIL_FORM
}

export type SettingActionTypes =
  OpenSettingModalAction | CloseSettingModalAction | ToggleEditEmailFormAction;
