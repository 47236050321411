import Utils from '../../utils/utils';

import {
  OPEN_NOTICE_MODAL,
  CLOSE_NOTICE_MODAL,
  SWITCH_TO_FORM,
  SWITCH_TO_DETAIL,
  SWITCH_TO_EDIT_FORM,
  ON_CHANGE_TITLE,
  ON_CHANGE_START_DATE,
  ON_CHANGE_DESCRIPTION,
  GET_ALL_NOTICE,
  INITIAL_TARGET_ID,
  CREATE_NOTICE,
  UPDATE_NOTICE,
  DELETE_NOTICE,
  LOAD_START_FOR_NOTICE,
  LOAD_END_FOR_NOTICE,
  NoticeState,
  NoticeActionTypes,
} from './types';

const initialTargetNotice = {
  id: INITIAL_TARGET_ID, title: '', start_date: new Date(), description: '',
};

const initialState: NoticeState = {
  modalVisible: false,
  formShown: true,
  title: '',
  start_date: null,
  description: '',
  notices: [],
  targetNotice: initialTargetNotice,
  loading: true,
};

const noticeReducer = (state = initialState, action: NoticeActionTypes) => {
  switch (action.type) {
    case OPEN_NOTICE_MODAL:
      return {
        ...state,
        modalVisible: true,
        formShown: true,
      };
    case CLOSE_NOTICE_MODAL:
      return {
        ...state,
        modalVisible: false,
      };
    case SWITCH_TO_FORM:
      return {
        ...state,
        formShown: true,
        targetNotice: initialTargetNotice,
        title: initialTargetNotice.title,
        start_date: null,
        description: initialTargetNotice.description,
      };
    case SWITCH_TO_DETAIL: {
      const targetNotice = state.notices.filter((notice) => notice.id === Number(action.payload));
      return {
        ...state,
        formShown: false,
        targetNotice: targetNotice[0],
      };
    }
    case SWITCH_TO_EDIT_FORM:
      return {
        ...state,
        formShown: true,
        title: state.targetNotice.title,
        start_date: Utils.castToDateObject(state.targetNotice.start_date),
        description: state.targetNotice.description,
      };
    case ON_CHANGE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case ON_CHANGE_START_DATE:
      return {
        ...state,
        start_date: action.payload,
      };
    case ON_CHANGE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case GET_ALL_NOTICE:
      return {
        ...state,
        notices: action.payload,
      };
    case CREATE_NOTICE:
      return {
        ...state,
        notices: [...state.notices, action.payload],
        title: '',
        start_date: null,
        description: '',
      };
    case UPDATE_NOTICE:
      return {
        ...state,
        notices: state.notices.map((notice) => (
          notice.id === action.payload.id ? action.payload : notice
        )),
        formShown: false,
        targetNotice: action.payload,
      };
    case DELETE_NOTICE:
      return {
        ...state,
        formShown: true,
        targetNotice: initialTargetNotice,
        notices: state.notices.filter((notice) => notice.id !== action.payload),
        title: '',
        start_date: null,
        description: '',
      };
    case LOAD_START_FOR_NOTICE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_END_FOR_NOTICE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default noticeReducer;
