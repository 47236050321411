export const OPEN_CREATE_ACCOUNT_MODAL = 'OPEN_CREATE_ACCOUNT_MODAL';
export const CLOSE_CREATE_ACCOUNT_MODAL = 'CLOSE_CREATE_ACCOUNT_MODAL';
export const ON_CHANGE_EMAIL = 'ON_CHANGE_EMAIL';
export const ON_CHANGE_PASSWORD = 'ON_CHANGE_PASSWORD';
export const ON_CHANGE_PASSWORD_CONFIRMATION = 'ON_CHANGE_PASSWORD_CONFIRMATION';

export type CreateAccountState = {
  email: string
  password: string
  passwordConfirmation: string
  visible: boolean
}

type OpenCreateAccountModalAction = {
  type: typeof OPEN_CREATE_ACCOUNT_MODAL
}

type CloseCreateAccountModalAction = {
  type: typeof CLOSE_CREATE_ACCOUNT_MODAL
}

type OnChangeEmailAction = {
  type: typeof ON_CHANGE_EMAIL
  payload: string
}

type OnChangePasswordAction = {
  type: typeof ON_CHANGE_PASSWORD
  payload: string
}

type OnChangePasswordConfirmationAction = {
  type: typeof ON_CHANGE_PASSWORD_CONFIRMATION
  payload: string
}

export type CreateAccountActionTypes =
  OpenCreateAccountModalAction
  | CloseCreateAccountModalAction
  | OnChangeEmailAction
  | OnChangePasswordAction
  | OnChangePasswordConfirmationAction;
