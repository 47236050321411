export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const TRANSITION_EXIT = 'TRANSITION_EXIT';

export type MenuState = {
  visible: boolean
  inProp: boolean
}

type OpenMenuAction = {
  type: typeof OPEN_MENU
}

type CloseMenuAction = {
  type: typeof CLOSE_MENU
}

type TransitionExitAction = {
  type: typeof TRANSITION_EXIT
}

export type MenuActionTypes = OpenMenuAction | CloseMenuAction | TransitionExitAction;
