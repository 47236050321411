import { Customer } from '../customer/types';

export const GET_THIS_MONTH_ALERT = 'GET_THIS_MONTH_ALERT';
export const GET_NEXT_MONTH_ALERT = 'GET_NEXT_MONTH_ALERT';

export type AlertState = {
  thisMonthAlert: Customer[]
  nextMonthAlert: Customer[]
}

type GetThisMonthAlertAction = {
  type: typeof GET_THIS_MONTH_ALERT
  payload: Customer[]
}

type GetNextMonthAlertAction = {
  type: typeof GET_NEXT_MONTH_ALERT
  payload: Customer[]
}

export type AlertActionType = GetThisMonthAlertAction | GetNextMonthAlertAction;
