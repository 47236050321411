import {
  ON_CHANGE_SESSION_EMAIL,
  ON_CHANGE_SESSION_PASSWORD,
  SIGN_IN,
  SIGN_OUT,
  LOAD_START,
  LOAD_END,
  RELOAD_END,
  SessionState,
  SessionActionTypes,
} from './types';

const initialState: SessionState = {
  reloading: true,
  isLoading: false,
  isSignIn: false,
  email: '',
  password: '',
};

const sessionReducer = (state = initialState, action: SessionActionTypes) => {
  switch (action.type) {
    case ON_CHANGE_SESSION_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case ON_CHANGE_SESSION_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case SIGN_IN:
      return {
        ...state,
        reloading: false,
        isLoading: false,
        isSignIn: true,
        password: '',
      };
    case SIGN_OUT:
      return {
        ...initialState,
      };
    case LOAD_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_END:
      return {
        ...state,
        isLoading: false,
      };
    case RELOAD_END:
      return {
        ...state,
        reloading: false,
      };
    default:
      return state;
  }
};

export default sessionReducer;
