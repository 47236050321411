// CreateAccount
export const failToCreateAccountTitle = 'アカウントを作成できませんでした';

export const completeCreateAccount = 'アカウントを作成しました';

export const doesntMatchPasswordAndPasswordConfirmation = 'パスワードと確認用パスワードが一致しません';

// Session
export const failToSignInTitle = 'サインインできませんでした';

export const failToSignOutTitle = 'サインアウトできませんでした';

export const confirmSignOut = 'サインアウトします。よろしいですか？';

export const failToUpdateEmail = 'メールアドレスを更新できませんでした';

export const failToAuthorization = '認証に失敗しました';

export const failToAuthorizationDescription = 'ログインし直してください';

// AJAX
export const internalServerError = '通信に失敗しました';

export const internalServerErrorDescription = '通信環境の良いところで再度お試しください';

// Notice
export const createNoticeText = 'お知らせを配信';

export const confirmDeliverJustNow = 'このお知らせは作成後すぐに配信されます。\nよろしいですか？';

export const deleteNoticeText = 'このお知らせを削除します。よろしいですか？';

export const failToCreateNotice = 'お知らせを作成できませんでした';

export const failToUpdateNotice = 'お知らせを更新できませんでした';

export const failToDeleteNotice = 'お知らせを削除できませんでした';

export const withoutStartDate = '配信開始日を選択してください';

// Setting
export const confirmSendResetPasswordEmail = 'パスワード再設定用のメールを送信しますか？';

export const sentResetPasswordEmailText = 'パスワード再設定用のメールを送信しました';

export const failToSendResetPasswordEmail = 'パスワード再設定用のメールを送信しました';

// Sales
export const failToSearchSalesHistory = '検索に失敗しました';

export const withoutRangeDateForSearchSales = '期間を入力してください';

export const invalidDateForSearchSales = '期間を正しく入力してください';
