import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';

import firebase from '../firebase';
import { RootState } from '../store';
import * as sessionActions from '../store/session/actions';
import Menu from './Menu';
import Loading from './Loading';
import CustomerModal from './CustomerModal';
import KPI from './KPI';
import Alert from './Alert';
import IndexCustomer from './IndexCustomer';
import IndexSalesByCustomer from './IndexSalesByCustomer';
import Sales from './Sales';

const mapStateToProps = (state: RootState) => {
  const {
    customerModal,
    session,
    menu,
    sales,
  } = state;

  return {
    reloading: session.reloading,
    isSignIn: session.isSignIn,
    customerModalVisible: customerModal.visible,
    menuVisible: menu.visible,
    isSalesShown: sales.isSalesShown,
  };
};

const mapDispatchToProps = {
  signIn: sessionActions.signIn,
  reloadEnd: sessionActions.reloadEnd,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const Main = (props: PropsFromRedux) => {
  const {
    customerModalVisible,
    reloading,
    isSignIn,
    menuVisible,
    isSalesShown,
    signIn,
    reloadEnd,
  } = props;

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      signIn();
    } else {
      reloadEnd();
    }
  });

  if (reloading) {
    return <Loading />;
  }

  if (!isSignIn) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="wrapper">
      {menuVisible && <Menu />}
      <div className="main">
        <KPI />
        {isSalesShown ? (
          <div className="mainBottom">
            <Sales />
            <IndexSalesByCustomer />
          </div>
        ) : (
          <div className="mainBottom">
            <div className="alert">
              <Alert range="thisMonth" />
              <Alert range="nextMonth" />
            </div>
            <IndexCustomer />
          </div>
        )}
      </div>
      {customerModalVisible && <CustomerModal />}
    </div>
  );
};

export default connector(Main);
