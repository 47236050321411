import {
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  ConfirmState,
  ConfirmActionTypes,
} from './types';

const initialState: ConfirmState = {
  visible: false,
  type: 'success',
  title: '',
  description: '',
};

const confirmReducer = (state = initialState, action: ConfirmActionTypes) => {
  switch (action.type) {
    case OPEN_CONFIRM:
      return {
        visible: true,
        type: action.payload.type,
        title: action.payload.title,
        description: action.payload.description,
        confirm: action.payload.confirm,
      };
    case CLOSE_CONFIRM:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default confirmReducer;
