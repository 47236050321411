import {
  OPEN_MENU,
  CLOSE_MENU,
  TRANSITION_EXIT,
  MenuState,
  MenuActionTypes,
} from './types';

const initialState: MenuState = { visible: false, inProp: true };

const menuReducer = (state = initialState, action: MenuActionTypes) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        inProp: true,
        visible: true,
      };
    case CLOSE_MENU:
      return {
        ...state,
        visible: false,
      };
    case TRANSITION_EXIT:
      return {
        ...state,
        inProp: false,
      };
    default:
      return state;
  }
};

export default menuReducer;
