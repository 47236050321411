import {
  GET_CUSTOMERS_COUNT,
  GET_RECONTRACT_THIS_MONTH_COUNT,
  GET_RECONTRACT_NEXT_MONTH_COUNT,
  GET_RECONTRACT_PERCENTAGE,
  GET_USERS_COUNT,
  KPIState,
  KPIActiontypes,
} from './types';

const initialState: KPIState = {
  customersCount: 0,
  recontractThisMonthCount: 0,
  recontractNextMonthCount: 0,
  recontractPercentage: 0,
  usersCount: 0,
};

const kpiReducer = (state = initialState, action: KPIActiontypes) => {
  switch (action.type) {
    case GET_CUSTOMERS_COUNT:
      return {
        ...state,
        customersCount: action.payload,
      };
    case GET_RECONTRACT_THIS_MONTH_COUNT:
      return {
        ...state,
        recontractThisMonthCount: action.payload,
      };
    case GET_RECONTRACT_NEXT_MONTH_COUNT:
      return {
        ...state,
        recontractNextMonthCount: action.payload,
      };
    case GET_RECONTRACT_PERCENTAGE:
      return {
        ...state,
        recontractPercentage: action.payload,
      };
    case GET_USERS_COUNT:
      return {
        ...state,
        usersCount: action.payload,
      };
    default:
      return state;
  }
};

export default kpiReducer;
