import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import firebase from '../firebase';
import * as confirmActions from '../store/confirm/actions';
import * as sessionActions from '../store/session/actions';
import i18n from '../utils/i18n';
import { failToSignInTitle } from '../utils/text';

const mapStateToProps = (state: RootState) => {
  const { session } = state;
  return {
    email: session.email,
    password: session.password,
    isSignIn: session.isSignIn,
  };
};

const mapDispatchToProps = {
  onChangeEmail: sessionActions.onChangeEmail,
  onChangePassword: sessionActions.onChangePassword,
  loadStart: sessionActions.loadStart,
  loadEnd: sessionActions.loadEnd,
  openConfirm: confirmActions.openConfirm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const SignIn = (props: PropsFromRedux) => {
  const {
    email,
    password,
    onChangeEmail,
    onChangePassword,
    loadStart,
    loadEnd,
    openConfirm,
    isSignIn,
  } = props;

  const handleSignIn = async () => {
    loadStart();
    await firebase.auth().signInWithEmailAndPassword(email, password)
      .catch((error) => {
        loadEnd();
        const i18nMessage = i18n.firebaseErrors(error.code);
        openConfirm({
          type: 'error',
          title: failToSignInTitle,
          description: i18nMessage,
        });
      });
  };

  return (
    isSignIn ? <Redirect to="/" /> : (
      <div className="backScreen">
        <div className="session">
          <img src="logo.png" alt="ChickTag" className="session__logo" />
          <div className="session__title">顧客管理システム</div>
          <input
            type="text"
            className="session__email"
            placeholder="Email"
            value={email}
            onChange={(event) => onChangeEmail(event.target.value)}
          />
          <input
            type="password"
            className="session__password"
            placeholder="Password"
            value={password}
            onChange={(event) => onChangePassword(event.target.value)}
          />
          <button type="button" onClick={handleSignIn} className="session__button">
            Sign in
          </button>
        </div>
      </div>
    )
  );
};

export default connector(SignIn);
