import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import { UNIT_PRICE } from '../store/sales/types';
import { Customer } from '../store/customer/types';
import * as customerActions from '../store/customer/actions';
import i18n from '../utils/i18n';

const mapStateToProps = (state: RootState) => {
  const { customerModal } = state;
  return {
    selectedCustomer: customerModal.selectedCustomer,
  };
};

const mapDispatchToProps = {
  closeCustomerModal: customerActions.closeCustomerModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const stopPropagation = (event: any) => event.stopPropagation();

const addModifire = (selectedCustomer: Customer, key: string) => {
  switch (key) {
    case 'last_month_sales':
      return selectedCustomer[key].toLocaleString();
    default:
      return selectedCustomer[key];
  }
};

const CustomerModal = (props: PropsFromRedux) => {
  const { selectedCustomer, closeCustomerModal } = props;
  return (
    <CSSTransition in timeout={200} classNames="fade" appear>
      <div className="overlay" onClick={closeCustomerModal}>
        <div className="customer" onClick={stopPropagation}>

          {Object.keys(selectedCustomer).map((key, index) => (
            <div className="customerRow" key={String(index)}>
              <div className="customerRow__label">{i18n.customerInfo(key)}</div>
              <div className="customerRow__value">{addModifire(selectedCustomer, key)}</div>
            </div>
          ), selectedCustomer)}

          <div className="customerRow">
            <div className="customerRow__label">当月売上</div>
            <div className="customerRow__value">
              {(selectedCustomer.count * UNIT_PRICE).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default connector(CustomerModal);
