import Utils from '../../utils/utils';
import {
  SALES_SEARCH_LOAD_START,
  SALES_SEARCH_LOAD_END,
  SHOW_SALES,
  HIDE_SALES,
  ON_CHANGE_SALES_SEARCH_FROM,
  ON_CHANGE_SALES_SEARCH_TO,
  SEARCH_SALES_HISTORY,
  SalesState,
  SalesActionTypes,
} from './types';

const regexDate = Utils.formatMonthYear(new Date());

const initialState: SalesState = {
  isLoading: false,
  isSalesShown: false,
  selectedDateFrom: new Date(),
  selectedDateTo: new Date(),
  salesHistories: [],
  salesHistoryRange: `${regexDate}~${regexDate}`,
  totalSales: null,
  totalCustomerCount: null,
};

const salesReducer = (state = initialState, action: SalesActionTypes) => {
  switch (action.type) {
    case SALES_SEARCH_LOAD_START:
      return {
        ...state,
        isLoading: true,
      };
    case SALES_SEARCH_LOAD_END:
      return {
        ...state,
        isLoading: false,
      };
    case SHOW_SALES:
      return {
        ...state,
        isSalesShown: true,
      };
    case HIDE_SALES:
      return {
        ...state,
        isSalesShown: false,
      };
    case ON_CHANGE_SALES_SEARCH_FROM:
      return {
        ...state,
        selectedDateFrom: action.payload,
      };
    case ON_CHANGE_SALES_SEARCH_TO:
      return {
        ...state,
        selectedDateTo: action.payload,
      };
    case SEARCH_SALES_HISTORY: {
      const totalSales = action.payload.data.reduce(
        (currentSales, data) => currentSales + data.amount,
        0,
      );

      const totalCustomerCount = action.payload.data.reduce(
        (currentCount, data) => currentCount + data.customer_count,
        0,
      );

      return {
        ...state,
        isLoading: false,
        salesHistories: action.payload.data,
        salesHistoryRange: action.payload.salesHistoryRange,
        totalSales: totalSales.toLocaleString(),
        totalCustomerCount,
      };
    }
    default:
      return state;
  }
};

export default salesReducer;
