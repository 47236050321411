import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import * as customerActions from '../store/customer/actions';
import * as searchActions from '../store/search/actions';
import { UNIT_PRICE } from '../store/sales/types';

const mapStateToProps = (state: RootState) => {
  const { customer } = state;
  return {
    customers: customer.customers,
  };
};

const mapDispatchToProps = {
  openCustomerModal: customerActions.openCustomerModal,
  getAllCustomer: customerActions.getAllCustomers,
  clearAllFormValue: searchActions.clearAllFormValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const IndexSalesByCustomer = (props: PropsFromRedux) => {
  const {
    customers,
    openCustomerModal,
    getAllCustomer,
    clearAllFormValue,
  } = props;

  useEffect(() => {
    clearAllFormValue();
    getAllCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="indexCustomerWrapper">
      <div className="indexCustomer">
        <div className="indexCustomerHeader">
          <div className="indexCustomerHeader__name">企業名</div>
          <div className="indexCustomerHeader__count">
            <span className="indexCustomerHeader__label">利用ユーザ数</span>
          </div>
          <div className="indexCustomerHeader__thisMonthSales">
            <span className="indexCustomerHeader__label">当月売上</span>
          </div>
          <div className="indexCustomerHeader__lastMonthSales">
            <span className="indexCustomerHeader__label">前月売上</span>
          </div>
        </div>
        <div className="indexCustomerList">
          {customers.map((customer) => (
            <div
              key={customer.id}
              className="indexCustomerRow"
              onClick={() => openCustomerModal(customer)}
            >
              <div className="indexCustomerRow__name">{customer.name}</div>
              <div className="indexCustomerRow__count">{customer.count}</div>
              <div className="indexCustomerRow__thisMonthSales">
                {(customer.count * UNIT_PRICE).toLocaleString()}
              </div>
              <div className="indexCustomerRow__lastMonthSales">
                {customer.last_month_sales.toLocaleString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connector(IndexSalesByCustomer);
