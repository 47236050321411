// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_AGENT = 'GET_ALL_AGENT';

export type Agent = {
  id: number
  name: string
}

export type AgentState = {
  agents: Agent[]
}

type GetALlAgentAction = {
  type: typeof GET_ALL_AGENT
  payload: Agent[]
}

export type AgentActionTypes = GetALlAgentAction;
