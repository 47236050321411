import {
  OPEN_CUSTOMER_MODAL,
  CLOSE_CUSTOMER_MODAL,
  GET_ALL_CUSTOMERS,
  SEARCH_CUSTOMER,
  CustomerModalState,
  CustomerState,
  CustomerModalActionTypes,
  CustomerActionTypes,
} from './types';

const initialCustomer = {
  id: 0,
  name: '',
  address: '',
  in_charge: '',
  department: '',
  email: '',
  tel: '',
  from: '',
  period: '',
  count: 0,
  agent: '',
  last_month_sales: 0,
};

const initialModalState: CustomerModalState = { visible: false, selectedCustomer: initialCustomer };

const initialState: CustomerState = { customers: [] };

export const customerModalReducer = (
  state = initialModalState,
  action: CustomerModalActionTypes,
) => {
  switch (action.type) {
    case OPEN_CUSTOMER_MODAL:
      return {
        visible: true,
        selectedCustomer: action.payload,
      };
    case CLOSE_CUSTOMER_MODAL:
      return {
        visible: false,
        selectedCustomer: initialCustomer,
      };
    default:
      return state;
  }
};

export const customerReducer = (state = initialState, action: CustomerActionTypes) => {
  switch (action.type) {
    case GET_ALL_CUSTOMERS:
      return {
        customers: action.payload,
      };
    case SEARCH_CUSTOMER:
      return {
        customers: action.payload,
      };
    default:
      return state;
  }
};
