import {
  internalServerError,
  internalServerErrorDescription,
  failToAuthorization,
  failToAuthorizationDescription,
} from '../../utils/text';

export const OPEN_CONFIRM = 'OPEN_CONFIRM';
export const CLOSE_CONFIRM = 'CLOSE_CONFIRM';

export type ConfirmProps = {
  type: 'success' | 'error' | 'ask'
  title: string
  description: string
  confirm?: () => void
}

export const INTERNAL_SERVER_ERROR: ConfirmProps = {
  type: 'error',
  title: internalServerError,
  description: internalServerErrorDescription,
};

export const UN_AUTHORIZATION: ConfirmProps = {
  type: 'error',
  title: failToAuthorization,
  description: failToAuthorizationDescription,
};

export type ConfirmState = ConfirmProps &{
  visible: boolean
}

type OpenConfirmAction = {
  type: typeof OPEN_CONFIRM,
  payload: ConfirmProps
}

type CloseConfirmAction = {
  type: typeof CLOSE_CONFIRM,
}

export type ConfirmActionTypes = OpenConfirmAction | CloseConfirmAction;
