import { OPEN_SETTING_MODAL, CLOSE_SETTING_MODAL, TOGGLE_EDIT_EMAIL_FORM } from './types';

export const openSettingModal = () => ({
  type: OPEN_SETTING_MODAL,
});

export const closeSettingModal = () => ({
  type: CLOSE_SETTING_MODAL,
});

export const toggleEditEmailForm = () => ({
  type: TOGGLE_EDIT_EMAIL_FORM,
});
