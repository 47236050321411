export const ON_CHANGE_SESSION_EMAIL = 'ON_CHANGE_SESSION_EMAIL';
export const ON_CHANGE_SESSION_PASSWORD = 'ON_CHANGE_SESSION_PASSWORD';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const LOAD_START = 'LOAD_START';
export const LOAD_END = 'LOAD_END';
export const RELOAD_END = 'RELOAD_END';

export type SessionState = {
  reloading: boolean
  isLoading: boolean
  isSignIn: boolean
  email: string
  password: string
}

type OnChangeEmailAction = {
  type: typeof ON_CHANGE_SESSION_EMAIL
  payload: string
}

type OnChangePasswordAction = {
  type: typeof ON_CHANGE_SESSION_PASSWORD
  payload: string
}

type SignInAction = {
  type: typeof SIGN_IN
}

type SignOutAction = {
  type: typeof SIGN_OUT
}

type LoadStartAcion = {
  type: typeof LOAD_START
}

type LoadEndAcion = {
  type: typeof LOAD_END
}

type ReloadEndAcion = {
  type: typeof RELOAD_END
}

export type SessionActionTypes =
  OnChangeEmailAction
  | OnChangePasswordAction
  | SignInAction
  | LoadStartAcion
  | SignOutAction
  | LoadEndAcion
  | ReloadEndAcion;
