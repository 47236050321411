import {
  CLOSE_ALL_FORM,
  TOGGLE_SERACH_BY_NAME,
  TOGGLE_ORDER_BY_DATE,
  TOGGLE_ORDER_BY_COUNT,
  TOGGLE_FILTER_BY_AGENT,
  ON_CHANGE_NAME,
  ON_CHANGE_DATE_ORDER,
  ON_CHANGE_COUNT_ORDER,
  ON_CHANGE_AGENT,
  CLEAR_DATE_ORDER,
  CLEAR_COUNT_ORDER,
  CLEAR_AGENT_FILTER,
  CLEAR_ALL_FORM_VALUE,
  DEFAULT_ORDER,
  SearchState,
  SearchActionTypes,
} from './types';

const initialState: SearchState = {
  searchByNameVisible: false,
  orderByDateVisible: false,
  orderByCountVisible: false,
  filterByAgentVisible: false,
  name: '',
  orderByDate: DEFAULT_ORDER,
  orderByCount: DEFAULT_ORDER,
  selectedAgent: 0,
};

const searchReducer = (state = initialState, action: SearchActionTypes) => {
  switch (action.type) {
    case CLOSE_ALL_FORM:
      return {
        ...state,
        orderByDateVisible: false,
        orderByCountVisible: false,
        filterByAgentVisible: false,
      };
    case TOGGLE_SERACH_BY_NAME:
      return {
        ...state,
        name: '',
        searchByNameVisible: !state.searchByNameVisible,
      };
    case TOGGLE_ORDER_BY_DATE:
      return {
        ...state,
        orderByDateVisible: !state.orderByDateVisible,
      };
    case TOGGLE_ORDER_BY_COUNT:
      return {
        ...state,
        orderByCountVisible: !state.orderByCountVisible,
      };
    case TOGGLE_FILTER_BY_AGENT:
      return {
        ...state,
        filterByAgentVisible: !state.filterByAgentVisible,
      };
    case ON_CHANGE_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case ON_CHANGE_DATE_ORDER:
      return {
        ...state,
        orderByDate: action.payload,
        orderByCount: DEFAULT_ORDER,
      };
    case ON_CHANGE_COUNT_ORDER:
      return {
        ...state,
        orderByCount: action.payload,
        orderByDate: DEFAULT_ORDER,
      };
    case ON_CHANGE_AGENT:
      return {
        ...state,
        selectedAgent: action.payload,
      };
    case CLEAR_DATE_ORDER:
      return {
        ...state,
        orderByDate: DEFAULT_ORDER,
        orderByDateVisible: false,
      };
    case CLEAR_COUNT_ORDER:
      return {
        ...state,
        orderByCount: DEFAULT_ORDER,
        orderByCountVisible: false,
      };
    case CLEAR_AGENT_FILTER:
      return {
        ...state,
        selectedAgent: 0,
        filterByAgentVisible: false,
      };
    case CLEAR_ALL_FORM_VALUE:
      return {
        ...state,
        name: '',
        orderByDate: DEFAULT_ORDER,
        orderByCount: DEFAULT_ORDER,
        selectedAgent: 0,
        searchByNameVisible: false,
        orderByDateVisible: false,
        orderByCountVisible: false,
        filterByAgentVisible: false,
      };
    default:
      return state;
  }
};

export default searchReducer;
