export const OPEN_CUSTOMER_MODAL = 'OPEN_CUSTOMER_MODAL';
export const CLOSE_CUSTOMER_MODAL = 'CLOSE_MODAL';
export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';

export type Customer = {
  id: number
  name: string
  address: string
  in_charge: string
  department: string
  email: string
  tel: string
  from: string
  period: string
  count: number
  agent: string
  last_month_sales: number
  [key: string]: string | number
}

export type CustomerModalState = {
  visible: boolean
  selectedCustomer: Customer
}

export type CustomerState = {
  customers: Customer[]
}

type OpenModalAction = {
  type: typeof OPEN_CUSTOMER_MODAL
  payload: Customer
}

type CloseModalAction = {
  type: typeof CLOSE_CUSTOMER_MODAL
}

type GetAllCustomersAction = {
  type: typeof GET_ALL_CUSTOMERS
  payload: Customer[]
}

type SearchCustomerAction = {
  type: typeof SEARCH_CUSTOMER
  payload: Customer[]
}

export type SearchParams = {
  name: string
  order_by_date: number
  order_by_count: number
  agent_id: number
}

export type CustomerModalActionTypes = OpenModalAction | CloseModalAction;

export type CustomerActionTypes = GetAllCustomersAction | SearchCustomerAction;
