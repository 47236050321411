import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const stopPropagation = (event: any) => event.stopPropagation();

const Loading = () => (
  <div className="loading" onClick={stopPropagation}>
    <FontAwesomeIcon icon={['fas', 'spinner']} spin size="2x" className="loading__icon" />
  </div>
);

export default Loading;
