import {
  OPEN_SETTING_MODAL,
  CLOSE_SETTING_MODAL,
  TOGGLE_EDIT_EMAIL_FORM,
  SettingState,
  SettingActionTypes,
} from './types';

const initialState: SettingState = { settingModalVisible: false, editEmailFormVisible: false };

const settingReducer = (state = initialState, action: SettingActionTypes) => {
  switch (action.type) {
    case OPEN_SETTING_MODAL:
      return {
        ...state,
        settingModalVisible: true,
      };
    case CLOSE_SETTING_MODAL:
      return {
        ...state,
        settingModalVisible: false,
      };
    case TOGGLE_EDIT_EMAIL_FORM:
      return {
        ...state,
        editEmailFormVisible: !state.editEmailFormVisible,
      };
    default:
      return state;
  }
};

export default settingReducer;
