export const CLOSE_ALL_FORM = 'CLOSE_ALL_FORM';
export const TOGGLE_SERACH_BY_NAME = 'TOGGLE_SERACH_BY_NAME';
export const TOGGLE_ORDER_BY_DATE = 'TOGGLE_ORDER_BY_DATE';
export const TOGGLE_ORDER_BY_COUNT = 'TOGGLE_ORDER_BY_COUNT';
export const TOGGLE_FILTER_BY_AGENT = 'TOGGLE_FILTER_BY_AGENT';
export const ON_CHANGE_NAME = 'ON_CHANGE_NAME';
export const ON_CHANGE_DATE_ORDER = 'ON_CHANGE_DATE_ORDER';
export const ON_CHANGE_COUNT_ORDER = 'ON_CHANGE_COUNT_ORDER';
export const ON_CHANGE_AGENT = 'ON_CHANGE_AGENT';
export const CLEAR_DATE_ORDER = 'CLEAR_DATE_ORDER';
export const CLEAR_COUNT_ORDER = 'CLEAR_COUNT_ORDER';
export const CLEAR_AGENT_FILTER = 'CLEAR_AGENT_FILTER';
export const CLEAR_ALL_FORM_VALUE = 'CLEAR_ALL_FORM_VALUE';
export const DEFAULT_ORDER = 0;
export const ASC = 1;
export const DESC = 2;

export const orderOptions = [
  { label: '', value: DEFAULT_ORDER },
  { label: '昇順', value: ASC },
  { label: '降順', value: DESC },
];

export type SearchState = {
  searchByNameVisible: boolean
  orderByDateVisible: boolean
  orderByCountVisible: boolean
  filterByAgentVisible: boolean
  name: string
  orderByDate: number
  orderByCount: number
  selectedAgent: number
}

type CloseAllForm = {
  type: typeof CLOSE_ALL_FORM
}

type ToggleSearchByNameAction = {
  type: typeof TOGGLE_SERACH_BY_NAME
}

type ToggleOrderByDateAction = {
  type: typeof TOGGLE_ORDER_BY_DATE
}

type ToggleOrderByCountAction = {
  type: typeof TOGGLE_ORDER_BY_COUNT
}

type ToggleFilterByAgentAction = {
  type: typeof TOGGLE_FILTER_BY_AGENT
}

type onChangeNameAction = {
  type: typeof ON_CHANGE_NAME
  payload: string
}

type onChangeDateOrderAction = {
  type: typeof ON_CHANGE_DATE_ORDER
  payload: number
}

type onChangeCountAction = {
  type: typeof ON_CHANGE_COUNT_ORDER
  payload: number
}

type onChangeAgentAction = {
  type: typeof ON_CHANGE_AGENT
  payload: number
}

type ClearDateOrderAction = {
  type: typeof CLEAR_DATE_ORDER
}

type ClearCountOrderAction = {
  type: typeof CLEAR_COUNT_ORDER
}

type ClearAgentFilterAction = {
  type: typeof CLEAR_AGENT_FILTER
}

type ClearAllFormValue = {
  type: typeof CLEAR_ALL_FORM_VALUE
}

export type SearchActionTypes =
  CloseAllForm
  | ToggleSearchByNameAction
  | ToggleOrderByDateAction
  | ToggleOrderByCountAction
  | ToggleFilterByAgentAction
  | onChangeNameAction
  | onChangeDateOrderAction
  | onChangeCountAction
  | onChangeAgentAction
  | ClearDateOrderAction
  | ClearCountOrderAction
  | ClearAgentFilterAction
  | ClearAllFormValue
