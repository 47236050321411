import { GET_ALL_AGENT, AgentState, AgentActionTypes } from './types';

const initialState: AgentState = { agents: [] };

const agentReducer = (state = initialState, action: AgentActionTypes) => {
  switch (action.type) {
    case GET_ALL_AGENT:
      return {
        agents: action.payload,
      };
    default:
      return state;
  }
};

export default agentReducer;
