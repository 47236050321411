import {
  CLOSE_ALL_FORM,
  TOGGLE_SERACH_BY_NAME,
  TOGGLE_ORDER_BY_DATE,
  TOGGLE_ORDER_BY_COUNT,
  TOGGLE_FILTER_BY_AGENT,
  ON_CHANGE_NAME,
  ON_CHANGE_DATE_ORDER,
  ON_CHANGE_COUNT_ORDER,
  ON_CHANGE_AGENT,
  CLEAR_DATE_ORDER,
  CLEAR_COUNT_ORDER,
  CLEAR_AGENT_FILTER,
  CLEAR_ALL_FORM_VALUE,
} from './types';

export const closeAllForm = () => ({
  type: CLOSE_ALL_FORM,
});

export const toggleSearchByName = () => ({
  type: TOGGLE_SERACH_BY_NAME,
});

export const toggleOrderByDate = () => ({
  type: TOGGLE_ORDER_BY_DATE,
});

export const toggleOrderByCount = () => ({
  type: TOGGLE_ORDER_BY_COUNT,
});

export const toggleFilterByAgent = () => ({
  type: TOGGLE_FILTER_BY_AGENT,
});

export const onChangeName = (payload: string) => ({
  type: ON_CHANGE_NAME,
  payload,
});

export const onChangeDateOrder = (payload: number) => ({
  type: ON_CHANGE_DATE_ORDER,
  payload,
});

export const onChangeCountOrder = (payload: number) => ({
  type: ON_CHANGE_COUNT_ORDER,
  payload,
});

export const onChangeAgent = (payload: number) => ({
  type: ON_CHANGE_AGENT,
  payload,
});

export const clearDateOrder = () => ({
  type: CLEAR_DATE_ORDER,
});

export const clearCountOrder = () => ({
  type: CLEAR_COUNT_ORDER,
});

export const clearAgentFilter = () => ({
  type: CLEAR_AGENT_FILTER,
});

export const clearAllFormValue = () => ({
  type: CLEAR_ALL_FORM_VALUE,
});
