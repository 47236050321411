import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import * as kpiTypes from '../store/kpi/types';
import * as kpiActions from '../store/kpi/actions';
import * as menuActions from '../store/menu/actions';
import * as salesActions from '../store/sales/actions';

type KPIProps = {
  kpiType: kpiTypes.KPITypes
  value: number
  getKpiValue: (kpiType: kpiTypes.KPITypes) => Promise<void>
}

const mapStateToProps = (state: RootState) => {
  const { kpi, sales } = state;

  return {
    customersCount: kpi.customersCount,
    recontractThisMonthCount: kpi.recontractThisMonthCount,
    recontractNextMonthCount: kpi.recontractNextMonthCount,
    recontractPercentage: kpi.recontractPercentage,
    usersCount: kpi.usersCount,
    isSalesShown: sales.isSalesShown,
  };
};

const mapDispatchToProps = {
  getKpiValue: kpiActions.getKpiValue,
  openMenu: menuActions.openMenu,
  showSales: salesActions.showSales,
  hideSales: salesActions.hideSales,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const Sales = (props: PropsFromRedux) => {
  const {
    customersCount,
    recontractThisMonthCount,
    recontractNextMonthCount,
    recontractPercentage,
    usersCount,
    isSalesShown,
    getKpiValue,
    openMenu,
    showSales,
    hideSales,
  } = props;

  const kpiValue = (kpiType: kpiTypes.KPITypes) => {
    switch (kpiType) {
      case kpiTypes.CUSTOMERS_COUNT:
        return customersCount;
      case kpiTypes.RECONTRACT_THIS_MONTH_COUNT:
        return recontractThisMonthCount;
      case kpiTypes.RECONTRACT_NEXT_MONTH_COUNT:
        return recontractNextMonthCount;
      case kpiTypes.RECONTRACT_PERCENTAGE:
        return recontractPercentage;
      case kpiTypes.USERS_COUNT:
        return usersCount;
      default:
        return 0;
    }
  };

  return (
    <div className="mainTop">
      <div className="Kpi">
        <div className="menuIconContainer" onClick={openMenu}>
          <FontAwesomeIcon icon={['fas', 'ellipsis-v']} className="menuIcon" />
        </div>
        {kpiTypes.data.map((kpi) => (
          <KPI key={kpi} kpiType={kpi} value={kpiValue(kpi)} getKpiValue={getKpiValue} />))}
        <div className="ring">
          {isSalesShown ? (
            <div className="data--button" onClick={hideSales}>
              <FontAwesomeIcon icon={['fas', 'building']} />
              <div className="data__label--icon">顧客情報</div>
            </div>
          ) : (
            <div className="data--button" onClick={showSales}>
              <FontAwesomeIcon icon={['fas', 'coins']} />
              <div className="data__label--icon">売上</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const KPI = (props: KPIProps) => {
  const { kpiType, value, getKpiValue } = props;
  const classNameForValue = kpiType === '更新率' ? 'data__value--percent' : 'data__value';

  useEffect(() => {
    getKpiValue(kpiType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ring">
      <div className="data">
        <div className={classNameForValue}>{value}</div>
        <div className="data__label">{kpiType}</div>
      </div>
    </div>
  );
};

export default connector(Sales);
