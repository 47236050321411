import createAxiosInstance from '../../configureAxios';
import { AppDispatch } from '..';
import Utils from '../../utils/utils';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import {
  GET_CUSTOMERS_COUNT,
  GET_RECONTRACT_THIS_MONTH_COUNT,
  GET_RECONTRACT_NEXT_MONTH_COUNT,
  GET_RECONTRACT_PERCENTAGE,
  CUSTOMERS_COUNT,
  RECONTRACT_THIS_MONTH_COUNT,
  RECONTRACT_NEXT_MONTH_COUNT,
  RECONTRACT_PERCENTAGE,
  KPITypes,
} from './types';

/* eslint-disable import/prefer-default-export */
export const getKpiValue = (kpiType: KPITypes) => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  switch (kpiType) {
    case CUSTOMERS_COUNT: {
      const response = await axios.get('/customers/count').catch((error) => error.response);

      if (response.status === 401) {
        dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
        return;
      }

      if (response === undefined || response.status !== 200) {
        dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
        return;
      }
      dispatch({ type: GET_CUSTOMERS_COUNT, payload: response.data.count });
      break;
    }

    case RECONTRACT_THIS_MONTH_COUNT: {
      const response = await axios.get('/recontract/count', { params: { 'period': 'this-month' } })
        .catch((error) => error.response);

      if (response.status === 401) {
        dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
        return;
      }

      if (response === undefined || response.status !== 200) {
        dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
        return;
      }
      dispatch({ type: GET_RECONTRACT_THIS_MONTH_COUNT, payload: response.data.count });
      break;
    }

    case RECONTRACT_NEXT_MONTH_COUNT: {
      const response = await axios.get('/recontract/count', { params: { 'period': 'next-month' } })
        .catch((error) => error.response);

      if (response.status === 401) {
        dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
        return;
      }

      if (response === undefined || response.status !== 200) {
        dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
        return;
      }
      dispatch({ type: GET_RECONTRACT_NEXT_MONTH_COUNT, payload: response.data.count });
      break;
    }

    case RECONTRACT_PERCENTAGE: {
      const response = await axios.get('/recontract/percentage').catch((error) => error.response);

      if (response.status === 401) {
        dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
        return;
      }

      if (response === undefined || response.status !== 200) {
        dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
        return;
      }
      dispatch({ type: GET_RECONTRACT_PERCENTAGE, payload: response.data.percentage });
      break;
    }

    default:
      break;
  }
};
