import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import firebase from '../firebase';
import SignIn from './SignIn';
import Main from './Main';
import Confirm from './Confirm';
import Loading from './Loading';

const mapStateToProps = (state: RootState) => {
  const { session, confirm } = state;
  return {
    isLoading: session.isLoading,
    isSignIn: session.isSignIn,
    confirmVisible: confirm.visible,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const App = (props: PropsFromRedux) => {
  const { isLoading, confirmVisible } = props;

  firebase.auth().onAuthStateChanged(() => {});

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/signin"><SignIn /></Route>
          <Route exact path="/"><Main /></Route>
        </Switch>
      </BrowserRouter>
      {confirmVisible && <Confirm />}
      {isLoading && <Loading />}
    </>
  );
};

export default connector(App);
