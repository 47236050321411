import thunk from 'redux-thunk';
import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';

import { customerModalReducer, customerReducer } from './customer/reducers';
import searchReducer from './search/reducers';
import alertReducer from './alert/reducers';
import documentReducer from './document/reducers';
import confirmReducer from './confirm/reducers';
import createAccountReducer from './createAccount/reducers';
import sessionReducer from './session/reducers';
import settingReducer from './setting/reducers';
import agentReducer from './agent/reducers';
import kpiReducer from './kpi/reducers';
import menuReducer from './menu/reducers';
import noticeReducer from './notice/reducers';
import feedbackReducer from './feedback/reducers';
import salesReducer from './sales/reducers';

export const rootReducer = combineReducers({
  customerModal: customerModalReducer,
  customer: customerReducer,
  search: searchReducer,
  alert: alertReducer,
  document: documentReducer,
  confirm: confirmReducer,
  createAccount: createAccountReducer,
  session: sessionReducer,
  setting: settingReducer,
  agent: agentReducer,
  kpi: kpiReducer,
  menu: menuReducer,
  notice: noticeReducer,
  feedback: feedbackReducer,
  sales: salesReducer,
});

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose
}
declare const window: ExtendedWindow;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);
/* eslint-enable */

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch

export default store;
