export default class i18n {
  static customerInfo(key: string) {
    switch (key) {
      case 'id':
        return 'ID';
      case 'name':
        return '会社名';
      case 'address':
        return '住所';
      case 'in_charge':
        return '担当者名';
      case 'department':
        return '担当者部署';
      case 'email':
        return 'メールアドレス';
      case 'tel':
        return '電話番号';
      case 'from':
        return '契約日';
      case 'period':
        return '更新日';
      case 'industry':
        return '業種';
      case 'employees':
        return '従業員数';
      case 'count':
        return 'ユーザー数';
      case 'agent':
        return '代理店';
      case 'last_month_sales':
        return '前月売上';
      default:
        return '';
    }
  }

  static firebaseErrors(code: string) {
    switch (code) {
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
        return '';
      case 'auth/email-already-in-use':
        return 'このメールアドレスは使用されています';
      case 'auth/invalid-email':
        return 'メールアドレスの形式が正しくありません';
      case 'auth/user-disabled':
        return 'サービスの利用が停止されています';
      case 'auth/user-not-found':
        return 'メールアドレスまたはパスワードが違います';
      case 'auth/user-mismatch':
        return '認証されているユーザーと異なるアカウントが選択されました';
      case 'auth/weak-password':
        return 'パスワードは6文字以上にしてください';
      case 'auth/wrong-password':
        return 'メールアドレスまたはパスワードが違います';
      case 'auth/popup-blocked':
        return '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください';
      case 'auth/operation-not-supported-in-this-environment':
      case 'auth/auth-domain-config-required':
      case 'auth/operation-not-allowed':
      case 'auth/unauthorized-domain':
        return '現在この認証方法はご利用頂けません';
      case 'auth/requires-recent-login':
        return '認証の有効期限が切れています';
      default:
        return 'エラーが発生しました。しばらく時間をおいてお試しください';
    }
  }
}
