import { OPEN_CONFIRM, CLOSE_CONFIRM, ConfirmProps } from './types';

export const openConfirm = (payload: ConfirmProps) => ({
  type: OPEN_CONFIRM,
  payload,
});

export const closeConfirm = () => ({
  type: CLOSE_CONFIRM,
});
