import {
  OPEN_CREATE_ACCOUNT_MODAL,
  CLOSE_CREATE_ACCOUNT_MODAL,
  ON_CHANGE_EMAIL,
  ON_CHANGE_PASSWORD,
  ON_CHANGE_PASSWORD_CONFIRMATION,
  CreateAccountState,
  CreateAccountActionTypes,
} from './types';

const initialState: CreateAccountState = {
  visible: false,
  email: '',
  password: '',
  passwordConfirmation: '',
};

const createAccountReducer = (state = initialState, action: CreateAccountActionTypes) => {
  switch (action.type) {
    case OPEN_CREATE_ACCOUNT_MODAL:
      return {
        ...state,
        visible: true,
      };
    case CLOSE_CREATE_ACCOUNT_MODAL:
      return {
        ...initialState,
      };
    case ON_CHANGE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case ON_CHANGE_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case ON_CHANGE_PASSWORD_CONFIRMATION:
      return {
        ...state,
        passwordConfirmation: action.payload,
      };
    default:
      return state;
  }
};

export default createAccountReducer;
