import {
  OPEN_CREATE_ACCOUNT_MODAL,
  CLOSE_CREATE_ACCOUNT_MODAL,
  ON_CHANGE_EMAIL,
  ON_CHANGE_PASSWORD,
  ON_CHANGE_PASSWORD_CONFIRMATION,
} from './types';

export const openCreateAccountModal = () => ({
  type: OPEN_CREATE_ACCOUNT_MODAL,
});

export const closeCreateAccountModal = () => ({
  type: CLOSE_CREATE_ACCOUNT_MODAL,
});

export const onChangeEmail = (payload: string) => ({
  type: ON_CHANGE_EMAIL,
  payload,
});

export const onChangePassword = (payload: string) => ({
  type: ON_CHANGE_PASSWORD,
  payload,
});

export const onChangePasswordConfirmation = (payload: string) => ({
  type: ON_CHANGE_PASSWORD_CONFIRMATION,
  payload,
});
