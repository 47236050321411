import { OPEN_MENU, CLOSE_MENU, TRANSITION_EXIT } from './types';

export const openMenu = () => ({
  type: OPEN_MENU,
});

export const closeMenu = () => ({
  type: CLOSE_MENU,
});

export const transitionExit = () => ({
  type: TRANSITION_EXIT,
});
