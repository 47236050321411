import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../store';
import * as customerActions from '../store/customer/actions';
import * as alertActions from '../store/alert/actions';

const mapStateToProps = (state: RootState) => {
  const { alert } = state;
  return {
    thisMonthAlert: alert.thisMonthAlert,
    nextMonthAlert: alert.nextMonthAlert,
  };
};

const mapDispatchToProps = {
  openCustomerModal: customerActions.openCustomerModal,
  getThisMonthAlert: alertActions.getThisMonthAlert,
  getNextMonthAlert: alertActions.getNextMonthAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  range: 'thisMonth' | 'nextMonth'
}

const Alert = (props: Props) => {
  const {
    range,
    thisMonthAlert,
    nextMonthAlert,
    openCustomerModal,
    getThisMonthAlert,
    getNextMonthAlert,
  } = props;

  const alerts = range === 'thisMonth' ? thisMonthAlert : nextMonthAlert;

  useEffect(() => {
    if (range === 'thisMonth') {
      getThisMonthAlert();
    } else if (range === 'nextMonth') {
      getNextMonthAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="alertWrapper">
      <div className="alertCard">
        <div className="alertCardHeader">
          <div className="alertCardHeader__title">
            {range === 'thisMonth' ? '当月' : '翌月'}
            更新処理未対応アラート
          </div>
          <div className="alertCardHeader__length">{alerts.length}</div>
        </div>
        <div className="indexWrapper">
          <div className="indexHeader">
            <div className="indexHeader__client">クライアント名</div>
            <div className="indexHeader__period">更新日</div>
            <div className="indexHeader__agent">代理店名</div>
          </div>
          <div className="alertIndex">
            {alerts.map((customer) => (
              <div key={customer.id} className="alertRow" onClick={() => openCustomerModal(customer)}>
                <div className="alertRow__client">{customer.name}</div>
                <div className="alertRow__period">{customer.period}</div>
                <div className="alertRow__agent">{customer.agent}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(Alert);
