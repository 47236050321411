import createAxiosInstance from '../../configureAxios';
import { AppDispatch } from '..';
import Utils from '../../utils/utils';
import { OPEN_CONFIRM, INTERNAL_SERVER_ERROR, UN_AUTHORIZATION } from '../confirm/types';
import { OPEN_DOCUMENT_MODAL, CLOSE_DOCUMENT_MODAL, GET_ALL_DOCUMENTS } from './types';

export const openDocumentModal = () => ({
  type: OPEN_DOCUMENT_MODAL,
});

export const closeDocumentModal = () => ({
  type: CLOSE_DOCUMENT_MODAL,
});

export const getAllDocuments = () => async (dispatch: AppDispatch) => {
  const token = await Utils.getToken();
  const axios = createAxiosInstance(token);

  const response = await axios.get('/documents').catch((error) => error.response);

  if (response.status === 401) {
    dispatch({ type: OPEN_CONFIRM, payload: UN_AUTHORIZATION });
    return;
  }

  if (response === undefined || response.status !== 200) {
    dispatch({ type: OPEN_CONFIRM, payload: INTERNAL_SERVER_ERROR });
    return;
  }

  dispatch({ type: GET_ALL_DOCUMENTS, payload: response.data.documents });
};
