import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';

import { RootState } from '../store';
import { UNIT_PRICE } from '../store/sales/types';
import * as salesActions from '../store/sales/actions';
import * as confirmActions from '../store/confirm/actions';
import Loading from './Loading';
import { failToSearchSalesHistory, withoutRangeDateForSearchSales } from '../utils/text';
import Utils from '../utils/utils';

registerLocale('ja', ja);

const mapStateToProps = (state: RootState) => {
  const { sales, kpi, customer } = state;
  return {
    isLoading: sales.isLoading,
    selectedDateFrom: sales.selectedDateFrom,
    selectedDateTo: sales.selectedDateTo,
    salesHistories: sales.salesHistories,
    salesHistoryRange: sales.salesHistoryRange,
    totalSales: sales.totalSales,
    totalCustomerCount: sales.totalCustomerCount,
    usersCount: kpi.usersCount,
    customersCount: kpi.customersCount,
    customers: customer.customers,
  };
};

const mapDispatchToProps = {
  onChangeSalesSearchFrom: salesActions.onChangeSalesSearchFrom,
  onChangeSalesSearchTo: salesActions.onChangeSalesSearchTo,
  seachSalesHistory: salesActions.seachSalesHistory,
  openConfirm: confirmActions.openConfirm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const Sales = (props: PropsFromRedux) => {
  const {
    isLoading,
    selectedDateFrom,
    selectedDateTo,
    salesHistories,
    salesHistoryRange,
    totalSales,
    totalCustomerCount,
    usersCount,
    customersCount,
    customers,
    onChangeSalesSearchFrom,
    onChangeSalesSearchTo,
    seachSalesHistory,
    openConfirm,
  } = props;

  const search = () => {
    if (selectedDateFrom === null || selectedDateTo === null) {
      openConfirm({
        type: 'error',
        title: failToSearchSalesHistory,
        description: withoutRangeDateForSearchSales,
      });
      return;
    }

    const params = { from: selectedDateFrom, to: selectedDateTo };
    seachSalesHistory(params);
  };

  const initialTotalSales = (usersCount * UNIT_PRICE).toLocaleString();

  const lastMonthSales = customers.reduce(
    (currentValue, customer) => (
      customer.agent === '株式会社SNAPSHOT（テスト用）' ? currentValue : customer.last_month_sales
    ),
    0,
  );

  const targetDate = new Date();
  targetDate.setMonth(targetDate.getMonth() - 1);
  const lastMonthString = Utils.formatMonthYear(targetDate);
  const thisMonthString = Utils.formatMonthYear(new Date());

  return (
    <div className="sales">
      <div className="monthlySales">
        <div className="monthlySalesList">
          <div className="monthlySalesList__label">当月売上</div>
          <div className="monthlySalesResult">
            <div className="monthlySalesResult__range">{thisMonthString}</div>
            <div className="monthlySalesResult__value">{initialTotalSales}</div>
          </div>
        </div>
        <div className="monthlySalesList">
          <div className="monthlySalesList__label">前月売上</div>
          <div className="monthlySalesResult">
            <div className="monthlySalesResult__range">{lastMonthString}</div>
            <div className="monthlySalesResult__value">{lastMonthSales.toLocaleString()}</div>
          </div>
        </div>
      </div>

      <div className="salesHistory">
        {isLoading && <Loading />}

        <div className="salesHistory__label">期間検索</div>
        <div className="salesHistoryRange">
          <DatePicker
            showMonthYearPicker
            dateFormat="yyyy/MM"
            selected={selectedDateFrom}
            onChange={(date) => onChangeSalesSearchFrom(date)}
            className="salesHistoryRange__datePicker"
            locale="ja"
          />
          <div className="salesHistoryRange__spacer">~</div>
          <DatePicker
            showMonthYearPicker
            dateFormat="yyyy/MM"
            selected={selectedDateTo}
            onChange={(date) => onChangeSalesSearchTo(date)}
            className="salesHistoryRange__datePicker"
            locale="ja"
          />
          <button type="button" onClick={search} className="salesHistoryRange__search">検索</button>
        </div>

        <div className="salesHistoryIndex">
          <div className="salesHistoryIndexHeader">
            <div className="salesHistoryIndexHeader__range">期間</div>
            <div className="salesHistoryIndexHeader__sales">売上</div>
            <div className="salesHistoryIndexHeader__customerCount">企業数</div>
          </div>

          <div className="salesHistoryList">
            <div className="salesHistoryListRow">
              <div className="salesHistoryListRow__range">{salesHistoryRange}</div>
              <div className="salesHistoryListRow__sales">{totalSales || initialTotalSales}</div>
              <div className="salesHistoryListRow__customerCount">
                {totalCustomerCount === null ? customersCount : totalCustomerCount}
              </div>
            </div>

            {salesHistories.map((history) => (
              <div key={history.date} className="salesHistoryListRow">
                <div className="salesHistoryListRow__range">{history.date}</div>
                <div className="salesHistoryListRow__sales">{history.amount.toLocaleString()}</div>
                <div className="salesHistoryListRow__customerCount">{history.customer_count}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(Sales);
