import axios from 'axios';

export default (token = '') => {
  const instance = axios.create({
    // baseURL: 'http://192.168.2.13/customer-management-API/public',
    baseURL: 'https://stg-customer-api.chick-tag.jp',
  });

  instance.defaults.headers.post['Content-Type'] = 'application/json';
  instance.defaults.headers.put['Content-Type'] = 'application/json';

  if (token !== '') {
    // eslint-disable-next-line dot-notation
    instance.defaults.headers.common['Authorization'] = token;
  }

  return instance;
};
